import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import SearchBar from '../shared/SearchBar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import ShareIcon from '@mui/icons-material/Share';
import { UserButton } from '@clerk/clerk-react';
import RootStateInterface from '../../store/model';
import { Tooltip } from '@mui/material';

function UserBar() {
  const activeFlowData = useSelector((state: RootStateInterface) => {
    return state.activeFlow.data;
  });

  let nameContent = activeFlowData === undefined ? "No Data Loaded" : activeFlowData.name;
  nameContent = nameContent === undefined ? "" : nameContent;
  nameContent = nameContent.toUpperCase();

  async function handleShare() {
    // console.log(`Sharing ${window.location.href}`);
    await navigator.clipboard.writeText(window.location.href);
    alert(`Link copied and ready to paste.`);
  }

  const iconStyle = {
    color: 'action.active',
    mr: 1,
    my: 0.5,
    '&:hover': {
      color: 'black'
    }
  };

  return (
    <nav className="user-bar-wrapper">
      <div className="logo-search-wrapper">
        <div className="floit-logo">
          <Tooltip title="Home"><Link to="/"><HomeIcon sx={iconStyle} /></Link></Tooltip>
        </div>
        <SearchBar hideInputElement={true} />
      </div>
      <div className="flow-name">
        {nameContent}
      </div>
      <div className="user-logo">
        <Tooltip title="Share"><ShareIcon className="cursor-pointer" sx={iconStyle} onClick={handleShare} /></Tooltip>
        <Tooltip title="Help"><Link to="/help"><HelpIcon sx={iconStyle} /></Link></Tooltip>
        <Tooltip title="User Profile"><Link to="/user-profile"><AccountCircleIcon sx={iconStyle} /></Link></Tooltip>
        <UserButton />
      </div>
    </nav>
  );
}

export default UserBar;
