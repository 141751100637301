// FlOit Flowchart model
import Comment from './comment';

/* Tree of flows */
export default class Flow
{
  /** Graph ID of the flow */
  public readonly id: string;

  /** Name of the flow */
  public readonly name: string;

  /** Parent flow (if not at top level) */
  public parent: Flow | null;

  /** Children flows */
  public readonly children: Array<Flow>;

  /** Next peer flow */
  public next: Flow | null;

  /** Associated comments */
  public readonly comments: Array<Comment>;

  /** Constructor */
  constructor(id: string, name: string)
  {
    this.id = id;
    this.name = name;
    this.parent = null;
    this.children = [];
    this.next = null;
    this.comments = [];
  }

  /** Set the parent */
  public setParent(parent: Flow)
  {
    this.parent = parent;
  }

  /** Read from a graphQL result (array of Flow objects) */
  public static readRootFromGraphQL(graphFlows: any): Flow
  {
    const root = new Flow("root", "root");

    // Load all the flows into a map
    const flows: { [ key: string ]: Flow } = {};
    for(const gflow of graphFlows)
    {
      const id = gflow.id;
      const name = gflow.names.length ? gflow.names[0].value : "?";
      const flow = new Flow(id, name);
      flows[id] = flow;
    }

    // Work out which flows are root-level, by crossing off any which
    // are either children or siblings of a next
    const notAtRoot: { [ id: string ]: boolean } = {};

    // Check for children with startsWith - this ensure it will be
    // the first in the child list
    for(const gflow of graphFlows)
    {
      const flow = flows[gflow.id];
      const child = gflow.startsWith?.id && flows[gflow.startsWith.id];
      if (child)
      {
        flow.children.push(child);
        child.setParent(flow);
        notAtRoot[child.id] = true;
      }
    }

    // Connect up peers with followedBy relationships
    for(const gflow of graphFlows)
    {
      const flow = flows[gflow.id];
      const peer = gflow.followedBy?.id && flows[gflow.followedBy.id];
      if (peer)
      {
        flow.next = peer;
        notAtRoot[peer.id] = true;
      }
    }

    // Copy parent-child relationships forward along 'next' chains.
    // recursively from roots
    const copyParentAlongChainFrom = (flow: Flow, parent: Flow) => {
      // Pass parent along to next, recursively
      if (flow.next)
      {
        flow.next.setParent(parent);
        parent.children.push(flow.next);
        copyParentAlongChainFrom(flow.next, parent);
      }

      // Recurse down to the first child only (more will be added)
      // quoting ourselves as its parent
      if (flow.children.length)
        copyParentAlongChainFrom(flow.children[0], flow);
    }

    // Do this for all roots, and while we're at it, set up the root
    // parent/child relationship for them
    for(const flow of Object.values(flows))
    {
      if (!notAtRoot[flow.id])
      {
        root.children.push(flow);
        flow.setParent(root);
        copyParentAlongChainFrom(flow, root);
      }
    }

    return root;
  }

  /** Read from a graphQL result (array of Flow objects) */
  public static readFlowFromGraphQL(graphFlow: any): any {
    if (graphFlow.length > 1) {
      throw new Error("Enexpected input");
    };
    
    const gflow = graphFlow[0];
    
    // parentFlowID - used to set OPEN FLOW - if null, open flow is self, if !null use provided ID
    const parentFlowID = gflow.parent === null ? gflow.id : gflow.parent.id
    const resultFlow = reducer(gflow, parentFlowID);

    function reducer(gflow: any, parentFlowID: string) {
      gflow = JSON.parse(JSON.stringify(gflow));
      if (gflow.children === undefined) {
        gflow.children = [];
      };
      if (gflow.children.length === 0 ) {
        const resultFlow = {
          id: gflow.id,
          name: gflow.names.length ? gflow.names[0].value : "?",
          children: [],
          discussion: [],
          parentId: parentFlowID
        }
        return resultFlow;
      } else if (gflow.children.length > 0 ) {
        const resultFlow = {
          id: gflow.id,
          name: gflow.names.length ? gflow.names[0].value : "?",
          children: gflow.children.map((el: any) => {
            return reducer(el, gflow.id);
          }),
          discussion: [],
          parentId: parentFlowID
        }
        return resultFlow;
      } else {
        return;
      }
    }

    return resultFlow;
  }
}
