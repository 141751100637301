import Flow from "../model/flow";
import Comment from "../model/comment";
import FlowDataProvider, { CreateFlowParams } from './FlowDataProvider';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, gql } from "@apollo/client";
// import { v4 as uuid } from 'uuid';

/** GraphQL data provider interface for Flow data */
export default class GraphQLFlowDataProvider implements FlowDataProvider {
  /** Apollo server URL */
  private readonly apolloURL: string;

  /** Apollo client */
  private readonly client: ApolloClient<NormalizedCacheObject>;

  /** Constructor */
  constructor(apolloURL: string) {
    this.apolloURL = apolloURL;
    this.client = new ApolloClient({
      uri: this.apolloURL,
      cache: new InMemoryCache()
    });
  }

  /** Get the root flow and all its descendants for the given organisation */
  public async getRootFlow(organisationId: string): Promise<Flow> {
    // Query to get basic flow tree
    const query = gql`
    query AllFlows($organisationId: ID!) {
      flows(where: { organisation:{ id: $organisationId }})
      {
        id
        names { value }
        startsWith { id }
        followedBy { id }
      }
    }`;

    try {
      const result = await this.client.query({
        query: query,
        variables: {
          organisationId: organisationId
        }
      });

      if (result?.data?.flows)
        return Flow.readRootFromGraphQL(result.data.flows);
      else
        throw Error("Failed to read root flow - bad response");
    }
    catch (e) {
      return Promise.reject(e);
    }
  }

  /** Get a specific flow */
  public async getFlow(flowId: string): Promise<Flow> {
    // Query to get basic flow tree
    const query = gql`
      query Flows($flowId: ID!) {
        flows(where: { id: $flowId }) {
          id
          names {
            value
          }
          parent { id }
          hasChild
          children {
            id
            names {
              value
            }
            children {
              id
              names {
                value
              }
            }
          }
          nestedComments {
            id
            text
            children {
              id
            }
            parent {
              id
            }
            user {
              name
            }
            userConnection {
              edges {
                on
              }
            }
            votesAggregate {
              edge {
                vote {
                  sum
                }
              }
            }
            votes {
              userId: id
              userName: name
              votesConnection {
                edges {
                  vote
                }
              }
            }
          }
          commentTree
        }
      }`;

    try {
      const result = await this.client.query({
        query: query,
        variables: {
          flowId: flowId
        }
      });

      if (result?.data?.flows) {
        return Flow.readFlowFromGraphQL(result.data.flows);
      }

      else
        throw Error("Failed to fetch the flow - bad response");
    }
    catch (e) {
      return Promise.reject(e);
    }
  }

  /** Get the root flow and all its descendants for the given organisation */
  // @ts-ignore
  public async getFlowWithComments(flowId: string): Promise<Flow> {
    // // Query to get basic flow tree
    // const query = gql`
    // query AllFlows($organisationId: ID!) {
    //   flows(where: { organisation:{ id: $organisationId }})
    //   {
    //     id
    //     names { value }
    //     startsWith { id }
    //     followedBy { id }
    //   }
    // }`;

    // try {
    //   const result = await this.client.query({
    //     query: query,
    //     variables: {
    //       organisationId: flowId
    //     }
    //   });

    //   if (result?.data?.flows)
    //     return Flow.readFromGraphQL(result.data.flows);
    //   else
    //     throw Error("Failed to read flow with comments - bad response");
    // }
    // catch (e) {
    //   return Promise.reject(e);
    // }
  }

  /** Get the comments associated with a flow */
  public async getCommentsFor(flowId: string): Promise<Array<Comment>> {
    // const comments = new Array<Comment>();
    const query = gql`
    query GetComments($flowId: ID!) {
      getComments(flowId: $flowId) {
        id
        text
        children {
          id
        }
        parent {
          id
        }
        user {
          name
        }
        userConnection {
          edges {
            on
          }
        }
        votesAggregate {
          edge {
            vote {
              sum
            }
          }
        }
        votes {
          userId: id
          userName: name
          votesConnection {
            edges {
              vote
            }
          }
        }
      }
      }`;

    try {
      const result = await this.client.query({
        query: query,
        variables: {
          flowId: flowId
        }
      });

      // console.log(result);
      if (result?.data?.getComments) {
        return Comment.readFromGraphQL(result.data.getComments);
      } else {
        throw Error("Failed to read comments - bad response");
      }
    }
    catch (e) {
      return Promise.reject(e);
    }
  }

  /** Create a sibling flow with the given parameters */
  /** @returns The created flow ID */
  public async createFlow(params: CreateFlowParams): Promise<string> {
    const mutation = gql`
    mutation CreateFlows ($userId: ID, $organisationId: ID, $name: String, $afterSiblingId: ID) {
      createFlows(
        input: [
          {
            user: {
              connect: {
                where: { node: { id: $userId } }
              }
            }
            organisation: {
              connect: {
                where: { node: { id: $organisationId } }
              }
            }
            names: { create: [{ node: { value: $name } }] }
            follows: {
              connect: {
                where: { node: { id: $afterSiblingId } }
              }
            }
          }
        ]
      ) {
        flows {
          id
        }
      }
    }`;

    const result = await this.client.mutate({
      mutation: mutation,
      variables: params
    });

    const flows = result?.data?.createFlows?.flows;
    if (flows && flows.length === 1)
      return flows[0].id;
    else
      throw Error("Failed to create flow ID - bad response: " +
        JSON.stringify(result, null, 2));
  }

  /** Create a sibling flow with the given parameters */
  /** @returns The created flow ID */
  public async createChildFlow(params: CreateFlowParams): Promise<string> {
    const mutation = gql`
      mutation CreateChildFlow ($userId: ID, $organisationId: ID, $name: String, $parentId: ID) {
        createFlows(
          input: [
            {
              starts: {
                connect: {
                  where: { node: { id: $parentId } }
                }
              }
              names: { create: [{ node: { value: $name } }] }
              user: {
                connect: {
                  where: { node: { id: $userId } }
                }
              }
              organisation: {
                connect: {
                  where: { node: { id: $organisationId } }
                }
              }
            }
          ]
        ) {
          flows {
            id
          }
        }
      }`;

    const result = await this.client.mutate({
      mutation: mutation,
      variables: params
    });

    const flows = result?.data?.createFlows?.flows;
    if (flows && flows.length === 1)
      return flows[0].id;
    else
      throw Error("Failed to create flow ID - bad response: " +
        JSON.stringify(result, null, 2));
  }

  /** Create parent flow, using Cypher-defined backend mutation */
  public async createFirstFlow(orgId: string, userId: string, name: string) {
    const mutation = gql`
      mutation CreateFirstFlow($orgId: ID!, $userId: ID!, $name: String!){
        createFirstFlow(orgId: $orgId, userId: $userId, name: $name) {
          id
        }
      }`;
    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        orgId: orgId,
        userId: userId,
        name: name
      }
    });

    const ids = result?.data?.createFirstFlow?.id;
    if (!ids)
      throw Error("Failed to create first flow - bad response: " +
        JSON.stringify(result, null, 2));
  }

  /** Delete a flow */
  public async deleteFlow(flowId: string) {
    console.log(`Deleted from GraphQLFlowDataProvider : ${flowId}`);
    const mutation = gql`
    mutation DeleteFlow($flowId: ID!){
      deleteFlow(id: $flowId)
    }`;

    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        flowId: flowId
      }
    });

    if (!result)
      throw Error("Bad response");
  }

  /** Set the name of a flow */
  public async renameFlow(flowId: string, name: string) {
    const mutation = gql`
    mutation RenameFlow($flowId: ID!, $name: String!){
      renameFlow(id: $flowId, newName: $name) {
        id
      }
    }`;
    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        flowId: flowId,
        name: name
      }
    });

    const ids = result?.data?.renameFlow?.id;
    if (!ids)
      throw Error("Failed to rename flow - bad response: " +
        JSON.stringify(result, null, 2));
  }

  /** Move a flow, either after another sibling, or (if omitted) as the
      startsWith of its parent.
      Parent can be omitted to leave it in the same one.
      If parent is set it disconnects from the existing parent and
      attempts to tidy that up. */
  public async moveFlow(movingFlowId: string, parentFlowId: string, afterSiblingId?: string) {
    console.log(`GQL_FDP :: Moving ${movingFlowId} , child of ${parentFlowId} after ${afterSiblingId}`);
    const mutation = gql`
    mutation MoveFlow($movingFlowId: ID!, $afterSiblingId: ID, $parentFlowId: ID!){
      moveFlow(movingFlowId: $movingFlowId, afterSiblingId: $afterSiblingId, parentFlowId: $parentFlowId)
    }`;

    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        movingFlowId: movingFlowId,
        afterSiblingId: afterSiblingId,
        parentFlowId: parentFlowId,
      }
    });

    if (!result)
      throw Error("Bad response");
  }

  /** Get User Data for a given userId - if userId doesn't exist then create a new user  */
  public async getUserById(params: { userName: string, userId: string, orgId: string, orgName: string }) {
    // console.log(`GQL_FDP :: Get me all the user Data for ${params.userId}`);
    // console.log(params);
    const mutation = gql`
      mutation MergeUser ($userName: String!, $userId: ID!, $orgId: ID!, $orgName: String!) {
        mergeUser(
          userId: $userId
          userName: $userName
          orgId: $orgId
          orgName: $orgName
        ) {
          name
          id
          memberOf(where: { id: $orgId }) {
            id
            name
          }
        }
    }`;

    try {
      const result = await this.client.mutate({
        mutation,
        variables: {
          userName: params.userName,
          userId: params.userId,
          orgId: params.orgId,
          orgName: params.orgName
        }
      });

      // console.log("result - expecting userName, userId, orgName, orgId");
      // console.log(result);

      if (result?.data) {
        const user = result.data.mergeUser;
        const org = user.memberOf[0];
        const _result = {
          org: {
            organisationId: org.id,
            organisationName: org.name
          },
          user: {
            userName: user.name,
            userId: user.id
          }
        }
        return _result;
      } else {
        throw Error("Failed to get user data - bad response");
      }
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
};
