import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import { UserButton } from '@clerk/clerk-react';

function UserBar() {

  const iconStyle = {
    color: 'action.active',
    mr: 1,
    my: 0.5,
    '&:hover': {
      color: 'black'
    }
  };

  return (
    <nav className="user-bar-wrapper">
      <div className="logo-search-wrapper">
        <div className="floit-logo">
          <Link to="/"><HomeIcon sx={ iconStyle } /></Link>
        </div>
      </div>
      <div className="flow-name">
      </div>
      <div className="user-logo">
        <Link to="/help"><HelpIcon sx={ iconStyle } /></Link>
        <Link to="/user-profile"><AccountCircleIcon sx={ iconStyle } /></Link>
        <UserButton />
      </div>
    </nav>
  );
}

export default UserBar;
