/** FlOit cytoscape flowchart generator */
import Flow from './flow';

export default class CytoscapeFlowchartGenerator {
  /** Generate a flowchart tree for Cytoscape from a root flow */
  public generateFrom(root: Flow): Array<any> {
    function addChildrenWithParent(root: Flow, parent: Flow | null, elements: Array<any>) {
      for (const flow of root.children) {
        elements.push({
          data: {
            id: flow.id, label: flow.name,
            parent: parent?.id
          }
        });

        if (flow.next)
          elements.push({
            data: {
              source: flow.id, target: flow.next.id,
              label: "Followed by"
            }
          });

        // Recurse
        if (flow.children.length)
          addChildrenWithParent(flow, flow, elements);
      }
    };

    const elements: Array<any> = [];
    addChildrenWithParent(root, null, elements);
    // console.log("Elements from Cytoscape generator:", elements);

    return elements;
  }

  /** Generate a flowchart tree for Cytoscape from an open flow */
  public generateFromOpenFlow(root: Flow): Array<any> {
    function addChildrenWithParent(root: Flow, parentId: string | null, elements: Array<any>) {
      for (let i = 0; i < root.children.length; i++) {
        const element = root.children[i];

        // Add element-node to elements array
        elements.push({
          data: {
            id: element.id,
            label: element.name,
            parent: parentId
          }
        });

        // Add "followed by" arrow-node if there is a next child in the array
        // console.log(`I : ${i}, length : ${root.children.length - 1}`);
        if (i < root.children.length - 1) {
          // console.log(`${root.children[i].name} joins with ${root.children[i + 1].name}`);
          elements.push({
            data: {
              // id: crypto.randomUUID(),
              source: element.id,
              target: root.children[i + 1].id,
              label: "Followed by"
            }
          });
        }

        // Recurse
        if (element.children.length) {
          addChildrenWithParent(element, element.id, elements);
        }

      }
    };

    const parent = {
      data: {
        id: root.id,
        label: root.name,
        parent: undefined
      }
    }
    const elements: Array<any> = [parent];

    addChildrenWithParent(root, root.id, elements);
    // console.log("Elements from Cytoscape generator:", elements);

    return elements;
  }

  /** Generate a flowchart tree for Cytoscape from an open flow */
  public generateJSONFromOpenFlow(root: Flow): { nodes: Array<any>, edges: Array<any> } {
    function addChildrenWithParent(root: Flow, parentId: string | null) {
      for (let i = 0; i < root.children.length; i++) {
        const element = root.children[i];

        // Add element-node to elements array
        elements.nodes.push({
          data: {
            id: element.id,
            label: element.name,
            parent: parentId
          }
        });

        // Add "followed by" arrow-node if there is a next child in the array
        // console.log(`I : ${i}, length : ${root.children.length - 1}`);
        if (i < root.children.length - 1) {
          // console.log(`${root.children[i].name} joins with ${root.children[i + 1].name}`);
          elements.edges.push({
            data: {
              source: element.id,
              target: root.children[i + 1].id,
              label: "Followed by"
            }
          });
        }

        // Recurse
        if (element.children.length) {
          addChildrenWithParent(element, element.id);
        }
      }
    };

    const parent = {
      data: {
        id: root.id,
        label: root.name,
        parent: undefined
      }
    }
    const nodes: Array<any> = [parent];
    const edges: Array<any> = [];
    const elements: { nodes: Array<any>, edges: Array<any> } = { nodes, edges };

    if (root && root.children) {
      addChildrenWithParent(root, root.id);
      return elements;
    } else {
      return { nodes: [], edges: [] }
    }
  }
}
