import React from "react";
import Flowchart from "./flowchartColumn/flowchart";

interface Props {
  activeClassName: (input: string) => string
}

function DynamicContent() {
  const hideFlowchart = document.documentElement.classList.contains('no-flowchart');
  if (hideFlowchart) {
    return null;
  } else {
    return <Flowchart />;
  }

}

function FlowChartColumn({ activeClassName }: Props) {
  return (
    <div className={activeClassName("flowchart-column-wrapper")}>
      <div className="expanded-content">
        <DynamicContent />
      </div>
    </div>
  );
}

export default FlowChartColumn;