import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import './App.scss';

// import NewLoginView from './views/newLoginView';
import ListFlowsView from './views/listFlowsView';
import HomeView from './views/home';
import SearchResultView from './views/search';
import MainView from './views/main';
import HelpView from './views/help';
import Page404 from './views/page404';
import FlowchartView from './views/flowchart';
import ReactFlowView from './views/reactflow';
import DarrenFlowchartFlowsView from './views/darren';
import { closeWebsocketSync } from './data/websocketDataProvider';
import { authLogout, clearActiveFlow, clearOpenFlow, clearRootFlow, fetchActiveFlow, store } from './store';
import ClerkProviderLayout from './components/auth/ClerkProviderLayout';
import UserSwitcherView from './views/userSwitcher';
import UserOrgView from './views/userOrg';

async function homepageLoader() {
  // console.log("calling");
  // function loader() {
  //   return new Promise((resolve) => {

  //     store.dispatch(authLogout({}));
  //     store.dispatch(clearRootFlow({}));
  //     store.dispatch(clearOpenFlow({}));
  //     store.dispatch(clearActiveFlow({}));

  //     setTimeout(() => {
  //       console.log("called 1");
  //       resolve("");
  //     }, 1000);
  //   });
  // }
  // await loader();
  store.dispatch(authLogout({}));
  store.dispatch(clearRootFlow({}));
  store.dispatch(clearOpenFlow({}));
  store.dispatch(clearActiveFlow({}));
  // console.log("called 2");
  return null;
}

async function mainViewLoader(arg: any) {
  // Close WebSocket from any previous views
  await closeWebsocketSync();

  const { urlActiveFlowId, urlOpenAndActiveFlowId } = arg.params;
  try {
    if (urlActiveFlowId === undefined) {
      // console.log(`setting ACTIVE and OPEN FLOW as ${urlActiveFlowId}`);
      // @ts-expect-error
      store.dispatch(fetchActiveFlow({ id: urlOpenAndActiveFlowId, setOpenFlow: "self" }));
    } else if (urlOpenAndActiveFlowId === undefined) {
      // console.log(`setting ACTIVE as ${urlOpenAndActiveFlowId} - OPEN FLOW SHOULD BE ITS PARENT`);
      // @ts-expect-error
      store.dispatch(fetchActiveFlow({ id: urlActiveFlowId, setOpenFlow: "parent" }));
    }
    // console.log(`Loading with params: ${urlActiveFlowId}`);
  } catch (error) {
    console.log(error);
  }
  return null;
}

function listflowsLoader() {
  // console.log("listflowsLoader");
  return null;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ClerkProviderLayout />
    ),
    errorElement: (
      <Page404 />
    ),
    loader: homepageLoader,
    children: [
      {
        path: "/",
        element: (
          <ListFlowsView />
        )
      },
      {
        path: "listflows",
        element: (
          <ListFlowsView />
        ),
        loader: listflowsLoader
      },
      {
        path: "x/home",
        element: (
          <HomeView />
        )
      },
      {
        path: "x/search",
        element: (
          <SearchResultView />
        )
      },
      {
        // Open ACTIVE FLOW as child, set PARENT ID as OPEN FLOW
        path: "main/c/:urlActiveFlowId",
        element: (
          <MainView />
        ),
        loader: mainViewLoader
      },
      {
        // Open ACTIVE FLOW as parent - it is also OPEN FLOW
        path: "main/p/:urlOpenAndActiveFlowId",
        element: (
          <MainView />
        ),
        loader: mainViewLoader
      },
      {
        path: "user-profile",
        element: (
          <UserOrgView />
        )
      },
      {
        path: "user-switch",
        element: (
          <UserSwitcherView />
        )
      },
      {
        path: "help",
        element: (
          <HelpView />
        )
      },
      {
        path: "x/flowchart",
        element: (
          <FlowchartView />
        )
      },
      {
        path: "x/reactflow",
        element: (
          <ReactFlowView />
        )
      },
      {
        path: "x/listFlowsView",
        element: (
          <ListFlowsView />
        )
      },
      {
        path: "x/darren",
        element: (
          <DarrenFlowchartFlowsView />
        )
      }
    ]
  },

]);

export default router;
