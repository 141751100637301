import { Button, Card, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  results?: any;
  result?: any;
  data?: any;
}

function Items({ results }: Props) {
  if (results.length > 0) {
    return results.map((result: any, index: number) => {
      return (
        <Item result={result} key={index} />
      )
    });
  } else {
    return <p>- No results to display -</p>;
  }
}

function Item({ result }: Props) {
  return (
    <li>
      <Card className="list-item" elevation={4}>
        <Typography variant="body1" component="h2">
          {result.name}
        </Typography>
        <Link to={`../main/p/${result.id}`}>
          <Button variant='outlined'>Open</Button>
        </Link>
      </Card>
    </li>
  )
}

function ResultsList({ results }: Props) {
  if (results === undefined) {
    return <p>No results data to display</p>
  } else {
    return (
      <Container>

        <ul className="search-results">
          <Items results={results} />
        </ul>
      </Container>
    )
  }
}

export default ResultsList;
