import React from 'react';
import { useSelector } from 'react-redux';

interface ListItemProps {
  itemId: string
}

// @TODO - THIS COMPONENT IS NO LONGER USED
function ActiveItemMarker({ itemId }: ListItemProps) {

  const activeFlowId = useSelector((state: any) => {
    try {
      return state.activeFlow.data.id;
    } catch (error) {
      console.error(error);
      return null;
    }
  });

  if (itemId === activeFlowId) {
    return <div className="item-marker active"></div>
  } else {
    return <div className="item-marker"></div>
  }
}

export default ActiveItemMarker;