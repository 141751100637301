import React from 'react';
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import { renameChildForOpenFlow, deleteChildForRootFlow } from '../../../store';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Tooltip } from '@mui/material';
import Prompt from '../../shared/Prompt';

interface ListItemProps {
  openFlowData: any,
  handleNavigate: any
  activeFlowId: any
}

function ListColumnFristItem({ openFlowData, handleNavigate, activeFlowId }: ListItemProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const textContent = openFlowData === undefined ? "No Data Loaded" : openFlowData.name

  const handleDelete = () => {
    if (openFlowData !== undefined && openFlowData.id !== undefined) {
      alert("Are you sure you want to delete this?");
      // @ts-ignore
      const action = deleteChildForRootFlow(openFlowData.id);
      // @ts-ignore
      dispatch(action);
      window.history.back();
    }
  }

  const handleRename = (name: string | null) => {
    if (openFlowData !== undefined && openFlowData.name !== undefined && openFlowData.id !== undefined) {
      if (name !== "" && name !== null) {
        // @ts-ignore
        const action = renameChildForOpenFlow({ flowId: openFlowData.id, name });
        // @ts-ignore
        dispatch(action)
          .unwrap()
          .then(() => {
            handleNavigate(`/main/p/${openFlowData.id}`);
          });
      }
    }
  }

  const handleBack = () => {
    // window.history.back();
    // @HERE MONDAY
    // console.log(openFlowData);
    // @TODO - need to expose "parent" on the open flow?
    if (openFlowData.parentId === openFlowData.id) {
      navigate(`/listflows`);
    } else {
      navigate(`/main/c/${openFlowData.id}`);
    }
  }

  return (
    <div>
      <Card className={activeFlowId === openFlowData.id ? "parent-item active" : "parent-item"}>
        <div className="history-icons">
          <Tooltip title="Back"><ArrowBackIosIcon className="cursor-pointer" onClick={handleBack} /></Tooltip>
        </div>
        <div className="title-wrapper">
          <Link to={`/main/p/${openFlowData.id}`}>
            <Button variant="outlined">
              <span className="">{textContent}</span>
            </Button>
          </Link>
        </div>
        <div className="icons">
          <Tooltip title="Delete"><DeleteForeverIcon className="cursor-pointer" onClick={handleDelete} /></Tooltip>
          <Prompt message="What is the new name for this flow?" defaultValue={openFlowData.name} callback={handleRename}>
            <Tooltip title="Rename"><EditIcon className="cursor-pointer" /></Tooltip>
          </Prompt>
        </div>
      </Card>
    </div>
  )

}

export default ListColumnFristItem;