import React, { useState } from 'react';
import { createActiveFlowComment } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import RootStateInterface from '../../../store/model';
import { useLocation } from 'react-router-dom';
import { Card } from '@mui/material';

interface Props {
  parentId: string
}

interface NewDiscussionProps {
  element: EventTarget & HTMLTextAreaElement;
}

function findParentRecursive(el: any): string {
  if (el.dataset.parentType !== undefined) {
    return el.dataset.parentType;
  } else {
    return findParentRecursive(el.parentElement);
  }
}

function NewDiscussion({ parentId }: Props) {
  let location = useLocation();
  const userData = useSelector((state: RootStateInterface) => {
    return state.auth.data.user;
  });
  const userNameText = userData !== undefined ? userData.userName : "User Name";
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    const isEnter: boolean = event.key === "Enter";
    const isNotShift: boolean = !event.shiftKey;

    if (isEnter && isNotShift) {
      event.preventDefault();
      addNewComment({ element: event.currentTarget });
    }
  }

  function handleChange(event: { target: { value: React.SetStateAction<string>; }; }) {
    setComment(event.target.value);
  }

  function addNewComment({ element }: NewDiscussionProps) {
    const parentType = findParentRecursive(element);

    let flowId: string | null = null;
    let commentId: string | null = null;

    if (parentType === "flow") {
      flowId = parentId;
    } else if (parentType === "comment") {
      commentId = parentId;
    } else {
      throw new Error("unhandled parent type");
    }

    // 'p' or 'c'
    let displayStyle = location.pathname.replace(/\/main\/(.?)\/(.*)/gm, "$1").toLowerCase();
    if (displayStyle === 'c') {
      displayStyle = 'child';
    } else if (displayStyle === 'p') {
      displayStyle = 'parent';
    }

    // Don't save empty comments to database
    if (comment !== "") {
      // @ts-ignore
      const action = createActiveFlowComment({ flowId, commentId, text: comment, displayStyle });
      // @ts-ignore
      dispatch(action);
      // clear form after submit
      setComment("");
    }
  }

  return (
    <li>
      <Card className="item new-item">
        <div className="left">
        </div>
        <div className="right">
          <h3 className="name">{userNameText}</h3>
          <div className="text-area-wrapper">
            <div className="pseudo-text-area">{comment} </div>
            <textarea
              className="text-area"
              rows={1}
              name="postContent"
              placeholder='Leave a comment…'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={comment} />
          </div>
        </div>
      </Card>
    </li>
  )
}

export default NewDiscussion;
