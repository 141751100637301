import { useEffect } from "react";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../../store";
// import { useLocation, useNavigate } from "react-router-dom";

function AuthProvider(): any {
    // console.log("Hello from AuthProvider");
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const location = useLocation();

    const org = useOrganization({});

    const { isLoaded, isSignedIn, user } = useUser();
    let userId = "1";
    if (user !== null && user !== undefined && user.id !== undefined) {
        userId = user?.id;
    }

    useEffect(() => {
        let _user = { userName: "", userId: "", orgId: "", orgName: "" };
        if (!isLoaded || !isSignedIn) {
            return;
        }
        if (org.isLoaded === true && org.organization === null) {
            // console.log(location);
            // if (location.pathname !== "/user-switch") {
            //     navigate("/user-switch");
            // }
            // return;

            // ------ this is where we would previously navigate to /user-switch ------
            _user.orgId = user.id;
            _user.orgName = `Personal Workspace - ${user.fullName}`;
        } else if (org?.organization?.id !== undefined && org?.organization?.name !== undefined) {
            _user.orgId = org?.organization?.id;
            _user.orgName = org?.organization?.name;
        } else {
            throw new Error("cannot proceed with insufficient data");
        }

        if (user.fullName === null || user.fullName === undefined) {
            throw new Error("user.fullName must be returned");
        }
        _user.userName = user.fullName;
        _user.userId = user.id;
        console.log(`Authenticating with ${JSON.stringify(_user)}`);

        // @ts-ignore
        const action = authenticateUser(_user)
        // @ts-ignore
        dispatch(action);
        // eslint-disable-next-line
    }, [userId, org]);

    return;
}

export default AuthProvider;