import React from 'react';
import LogoContainer from '../components/home/LogoContainer';
import SearchBar from '../components/shared/SearchBar';

function Home() {
  return (
    <div className="home-wrapper">
      <LogoContainer />
      <SearchBar ShowInlineSearchResuls={true} styleClass="style-large" />
    </div>
  );
}

export default Home;