// Example flowchart page fed from GraphQL model
import React, { useEffect, useState } from 'react';
import FlowDataProvider, { CreateFlowParams } from '../data/FlowDataProvider';
import GraphQLFlowDataProvider from '../data/GraphQLFlowDataProvider';
import CytoscapeFlowchartGenerator from '../model/cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';
import { Button } from '@mui/material';

// URL for Apollo server (floit-backend)
const apolloURL = "http://localhost:4000";
const organisationId = "19c86076-8467-4313-8c0d-6e9a58866613";
const userId = "3bfbf705-d7a4-449c-997a-58334d2c8afc";

// Cytoscape graph styling
const cytoscapeStylesheet = [
  {
    selector: 'node',
    style: {
      width: 20,
      height: 20,
      shape: 'rectangle',
      'background-color': '#ccc',
      label: 'data(label)'
    }
  },
  {
    selector: ':parent',
    style: {
      'background-color': '#eee'
    }
  },
  {
    selector: 'edge',
    style: {
      width: 2,
      'target-arrow-shape': 'triangle',
      'curve-style': 'bezier'
    }
  }
];

// Cytoscape graph layout
const cytoscapeLayout = {
  name: 'grid'
};
// const cytoscapeLayout = {
//   name: 'grid',
//   cols: 1,
//   padding: 5,
//   animate: true,
// };

// Flowchart view props
type FlowchartViewProps = {
};

// Flowchart view
const FlowchartView: React.FunctionComponent<FlowchartViewProps> = () => {
  const [cytoscapeModel, setCytoscapeModel] = useState<Array<any>>([]);
  const [provider, setProvider] = useState<FlowDataProvider | null>(null);

  // Create data provider
  useEffect(() => {
    // Note: Switch this for another one if you want
    setProvider(new GraphQLFlowDataProvider(apolloURL));
  }, []);

  // Fetch data on mount
  useEffect(() => {
    const fetch = async () => {
      if (provider) {
        try {
          const flow = await provider.getRootFlow(organisationId);
          const generator = new CytoscapeFlowchartGenerator();
          setCytoscapeModel(generator.generateFrom(flow));
          // console.log(flow);
        }
        catch (e) {
          console.error("Root flow fetch failed: " + e);
        }
      }
    };

    fetch();
  }, [provider]);

  async function create() {
    const params: CreateFlowParams = {
      organisationId: organisationId,
      userId: userId,
      name: "Created by button"
    };

    if (provider) {
      try {
        const id = await provider.createFlow(params);
        console.log(`Created flow ${id}`);
      }
      catch (e) {
        console.error("Flow creation failed: " + e);
      }
    }
  }

  async function rename() {
    if (provider) {
      try {
        // const id = await provider.renameFlow("8a6ade60-e8f2-41cb-9bdf-fec8f71f1f01", "Renaming test");;
        await provider.renameFlow("8a6ade60-e8f2-41cb-9bdf-fec8f71f1f01", "Renaming test");;
        // console.log(`Rename flow ${id}`);
        console.log("done");
      }
      catch (e) {
        console.error("Flow creation failed: " + e);
      }
    }
  }

  async function createFirstFlow() {
    if (provider) {
      try {
        const id = await provider.createFirstFlow("c0f6165d-aa97-49ab-8b0c-8a1168864150", "e92f9131-ec90-4759-8d6e-f92e29ca2c75", "Flow created by button");;
        console.log(`Created parent flow ${id}`);
      }
      catch (e) {
        console.error(`Parent flow creation failed: ${e}`);
      }
    }
  }

  return (
    <div className="flowchart-wrapper" style={{ textAlign: 'left' }}>
      <Button variant="contained" onClick={create}>Create</Button>
      <Button variant="contained" onClick={rename}>Rename</Button>
      <Button variant="contained" onClick={createFirstFlow}>Create a first flow</Button>
      <CytoscapeComponent elements={cytoscapeModel}
        style={{ width: '100%', height: '100%' }}
        pan={{ x: 300, y: 300 }}
        stylesheet={cytoscapeStylesheet}
        layout={cytoscapeLayout} />

    </div>
  );
};

export default FlowchartView;
