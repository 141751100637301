import React, { Component } from 'react';
import NestedChildredIcon from '../listColumn/NestedChildrenIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { deleteChildForOpenFlow, renameChildForOpenFlow, reorderChildForOpenFlow } from '../../../store';
import ActiveItemMarker from './activeItemMarker';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import Prompt from '../../shared/Prompt';

// @TODO - move to state?
const stash = {
  id: 0,
  sortOrder: 0,
  el: null,
  text: "",
  movingFlowId: "",
  initialOrder: 0
}

interface ListItemProps {
  item: any;
  dispatch: any;
  index: number;
  openFlowData: any;
  handleNavigate: any;
  activeFlowId: any;
}

class ListColumnItem extends Component<ListItemProps, {}> {
  dragSrcIndex: any;
  STRINGS: { PLACEHOLDER: string; };
  item: any;
  dispatch: any;
  index: any;
  id: any;
  openFlowData: any;
  handleNavigate: any;
  activeFlowId: any;
  constructor(props: any) {
    super(props);
    this.STRINGS = {
      PLACEHOLDER: "convert-to-placeholder"
    }
    this.dragSrcIndex = 0;
    const { item, dispatch, index, openFlowData, handleNavigate, activeFlowId } = props;
    this.item = item;
    this.dispatch = dispatch;
    this.index = index;
    this.openFlowData = openFlowData;
    this.handleNavigate = handleNavigate;
    this.activeFlowId = activeFlowId;

    this.handleRename = this.handleRename.bind(this);
  };

  findParentEl(el: any): any {
    if (el.dataset.id !== undefined) {
      return el;
    } else {
      return this.findParentEl(el.parentElement);
    }
  }

  handleDragStart(event: any) {
    const el = this.findParentEl(event.currentTarget);
    stash.text = el.querySelector('.link').innerHTML;
    stash.id = el.dataset.id;
    stash.el = el;
    stash.movingFlowId = el.dataset.id;
    stash.initialOrder = parseInt(el.dataset.sortOrder);

    requestAnimationFrame(() => {
      el.classList.add(this.STRINGS.PLACEHOLDER);
    });
  }

  handleDragOver(event: any) {
    event.preventDefault();
    return false;
  };

  handleDragEnter(event: any): void {
    const _currentTarget = this.findParentEl(event.currentTarget);

    const localStash = {
      text: _currentTarget.querySelector('.link').innerHTML,
      id: _currentTarget.dataset.id,
      el: _currentTarget
    }

    // UPDATE CONTENT FOR EL BEING HIDDEN
    _currentTarget.classList.add(this.STRINGS.PLACEHOLDER);
    _currentTarget.querySelector('.link').innerHTML = stash.text;
    _currentTarget.dataset.id = stash.id;

    // UPDATE CONTENT FOR EL BEING REVEALED
    // @ts-ignore pc
    stash.el.querySelector('.link').innerHTML = localStash.text;
    // @ts-ignore pc
    stash.el.dataset.id = localStash.id;
    // @ts-ignore pc
    stash.el.classList.remove(this.STRINGS.PLACEHOLDER);

    // UPDATE STASH FOR NEXT CHANGE
    stash.el = localStash.el;
  }

  handleDragLeave() {
    // STUB
  }

  handleDragEnd(event: any) {
    try {
      const el = event.target;
      el.style.opacity = '1';
      [...document.querySelectorAll(`.${this.STRINGS.PLACEHOLDER}`)].forEach((el) => el.classList.remove(`${this.STRINGS.PLACEHOLDER}`));
    } catch (error) {
      console.log(error);
    }
  }

  handleDrop(event: React.DragEvent<HTMLLIElement>) {
    event.stopPropagation();
    const flowId = stash.movingFlowId;
    const newIndex = event.currentTarget.dataset.sortOrder !== undefined ? parseInt(event.currentTarget.dataset.sortOrder) : stash.initialOrder
    // console.log(`Starting at ${stash.initialOrder}, going to ${newIndex}`);

    let afterSiblingId;
    if (newIndex === 0) {
      afterSiblingId = null;
    } else if (stash.initialOrder > newIndex) {
      // MOVING ITEM UP IN LIST    
      afterSiblingId = this.openFlowData.children[newIndex - 1].id;
    } else if (stash.initialOrder < newIndex) {
      // MOVING ITEM DOWN IN LIST
      afterSiblingId = this.openFlowData.children[newIndex].id;
    }

    if (stash.initialOrder !== newIndex) {
      // console.log("I think I'm moving : ", flowId);
      // @ts-ignore
      const action = reorderChildForOpenFlow({ flowId, afterSiblingId });
      this.dispatch(action);
    }
  }

  handleDelete(id: string) {
    // @ts-ignore
    const action = deleteChildForOpenFlow(id);
    this.dispatch(action);
  }

  handleRename(input: string) {
    // console.log("handleRename ", input);
    if (input !== "" && input !== null) {
      // @ts-ignore
      const action = renameChildForOpenFlow({ flowId: this.item.id, name: input });
      // @ts-ignore
      this.dispatch(action)
        .unwrap()
        .then(() => {
          this.handleNavigate(`/main/c/${this.item.id}`);
        });
    }
  }

  render() {
    try {
      return <li
        data-sort-order={this.index}
        data-id={this.item.id}
        draggable="true"
        onDragStart={(event) => this.handleDragStart(event)}
        onDragEnd={(event) => this.handleDragEnd(event)}
        onDragOver={(event) => this.handleDragOver(event)}
        onDragEnter={(event) => this.handleDragEnter(event)}
        onDragLeave={(event) => this.handleDragLeave()}
        onDrop={(event) => { this.handleDrop(event) }}
      >
        <Card className={this.activeFlowId === this.item.id ? "item active" : "item"}>
          <ActiveItemMarker itemId={this.item.id} />
          <div className="link">
            <Link to={`/main/c/${this.item.id}`}>
              <Button variant="outlined">
                {this.item.name}
              </Button>
            </Link>
          </div>
          <div className="icons">
            <Tooltip title="Delete"><DeleteForeverIcon className="cursor-pointer" onClick={() => { this.handleDelete(this.item.id) }} /></Tooltip>
            <Prompt message="What is the new name for this flow?" defaultValue={this.item.name} callback={this.handleRename}>
              <Tooltip title="Rename"><EditIcon className="cursor-pointer" /></Tooltip>
            </Prompt>
            <NestedChildredIcon children={this.item.children} parentId={this.item.id} parentName={this.item.name} />
          </div>
        </Card>
      </li>
    } catch (error) {
      console.log(error);
    }
  }
}

export default ListColumnItem;
