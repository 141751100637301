import React from 'react';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createChildForOpenFlow } from '../../../store';
import { useNavigate } from 'react-router';
import Card from '@mui/material/Card';
import Prompt from '../../shared/Prompt';


function NewListColumnItem() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleNewItemClick(name: string | null): void {
    if (name !== "" && name !== null) {
      // @ts-ignore
      const action = createChildForOpenFlow({ name });
      // @ts-ignore
      dispatch(action)
        .unwrap()
        .then((newFlowId: string) => {
          navigate(`/main/c/${newFlowId}`);
        });
    }
  }

  return (
    <li>
      <Prompt message="Name the new step" defaultValue="" callback={handleNewItemClick} >
        <Card className="item new-item">
          Add Step<AddCircleIcon />
        </Card>
      </Prompt>
    </li>
  )
}

export default NewListColumnItem;
