import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Container, TextField } from '@mui/material';
import { useState } from 'react';


export interface SimpleDialogProps {
  open: boolean;
  message: string;
  defaultValue: string;
  onClose: (value: string | null) => void;
  input: any
  setInput: any
}

function SimpleDialog(props: SimpleDialogProps) {
  const { open, message, input, setInput, onClose } = props;

  const handleLClickOK = () => {
    onClose(input);
  };

  const handleClickClose = () => {
    onClose(null);
  };

  const handleClose = () => {
    onClose(null);
  };

  const handleEnter = (e: any) => {
    if (e.code === "Enter") {
      onClose(input);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} onKeyPress={handleEnter}>
      <Container sx={{ paddingTop: '24px', paddingBottom: '24px' }}>
        <DialogTitle sx={{paddingLeft: 0, paddingRight: 0}}>{message}</DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <TextField id='prompt-input' value={input} onChange={(event) => setInput(event.target.value)} placeholder=""></TextField>
          <div style={{ display: 'flex', paddingTop: '24px', justifyContent: 'end' }}>
            <Button variant='outlined' onClick={handleClickClose} sx={{marginRight: '12px'}}>Cancel</Button>
            <Button variant='contained' onClick={handleLClickOK}>OK</Button>
          </div>
        </div>
      </Container>
    </Dialog>
  );
}

interface Props {
  children: any,
  callback: any
  message: string
  defaultValue: string
}

function Prompt({ children, callback, message, defaultValue }: Props) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(defaultValue);

  const handleClickOpen = () => {
    // console.log('handleOpen');
    setOpen(true);
    window.requestAnimationFrame(() => {
      const el = document.querySelector('#prompt-input');
      if (el !== null) {
        //@ts-ignore
        el.focus();
      }
    });
  };

  const handleClose = (value: string | null) => {
    // console.log('handleClose');
    setOpen(false);
    setInput(defaultValue);
    callback(value);
  };

  return (
    <>
      <span onClick={() => handleClickOpen()}>
        {children}
      </span>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        message={message}
        defaultValue={defaultValue}
        input={input}
        setInput={setInput}
      />
    </>
  );
}

export default Prompt;
