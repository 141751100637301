import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserBar from '../components/search/UserBar';
import SearchBar from '../components/shared/SearchBar';
import ResultsList from '../components/search/ResultsList';

import searchApi from '../api/search';

function Search() {
  let [searchParams] = useSearchParams();
  const query: any = searchParams.get('q');
  const [data, setData] = useState({ results: [] });
  searchApi(query, setData);

  return (
    <div className="search-wrapper">
      <UserBar />
      <SearchBar ShowCurrentSearchValue={true} query={query} styleClass="style-large" />
      <ResultsList results={data.results} />
    </div>
  );
}

export default Search;