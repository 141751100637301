
import CommentDataProvider from './CommentDataProvider';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, gql } from "@apollo/client";

/** GraphQL data provider interface for Comment data */
export default class GraphQLCommentDataProvider implements CommentDataProvider {
  /** Apollo server URL */
  private readonly apolloURL: string;

  /** Apollo client */
  private readonly client: ApolloClient<NormalizedCacheObject>;

  /** Constructor */
  constructor(apolloURL: string) {
    this.apolloURL = apolloURL;
    this.client = new ApolloClient({
      uri: this.apolloURL,
      cache: new InMemoryCache()
    });
  }

  /** Create a comment attached to a flow or a comment */
  public async createComment(flowId: string | null, commentId: string | null, text: string, authorId: string): Promise<any> {
    const mutation = gql`
      mutation CreateComments ($flowId: ID, $commentId: ID, $text: String, $authorId: ID) {
        createComments(
          input: [
            {
              text: $text
              user: { connect: { where: { node: { id: $authorId }}}}
              flow: { connect: { where: { node: { id: $flowId } } } }
              parent: { connect: { where: { node: { id: $commentId } } } }
            }
          ]
        ) {
          comments {
            id
          }
        }
      }`

    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        flowId,
        commentId,
        text,
        authorId
      }
    });

    const comment = result?.data?.createComments?.comments[0];
    if (comment && comment.id !== undefined) {
      return "success";
      // return comment.id;    
    } else {
      throw Error("Failed to create comment - bad response: " + JSON.stringify(result, null, 2));
    }
  }


  /** Vote on a comment */
  public async voteOnComment(commentId: string, value: number, userId: string): Promise<any> {
    console.log(`The comment ${commentId} got voted on : ${value} by ${userId}`);
    return "success";
  }

  /** Delete a comment */
  public async deleteComment(commentId: string): Promise<any> {
    console.log(`Deleted a comment and all it's children : ${commentId}`);
    const mutation = gql`
    mutation DeleteComments($where: CommentWhere) {
      deleteComments(where: $where) {
        nodesDeleted
      }
    }`

    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        "where": {
          "id": commentId
        }
      }
    });

    if (result?.data?.deleteComments?.nodesDeleted === 1) {
      return "success"
    } else {
      throw Error("Bad response");
    }
  }

  /** replace the text of a comment */
  public async editCommentText(commentId: string, text: string): Promise<any> {
    console.log(`The comment : ${commentId} has new text ${text}`);
    const mutation = gql`
      mutation UpdateComments($where: CommentWhere, $update: CommentUpdateInput) {
        updateComments(where: $where, update: $update) {
          comments {
            id
          }
        }
    }`
    const result = await this.client.mutate({
      mutation: mutation,
      variables: {
        "where": {
          "id": commentId
        },
        "update": {
          "text": text
        }
      }
    });

    console.log(result);
    return;
  }
};