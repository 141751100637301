import React from 'react';

function GridOverlay() {
  return (
    <div className="grid-overlay hidden">
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
      <span className="col"></span>
    </div>
  )
}

export default GridOverlay;