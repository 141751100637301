import { Component } from 'react';

class SliderBaseComponent extends Component {
  stash!: { x: number; y: number; };
  window!: { height: number; width: number; };
  threshold!: { min: number; max: number; };
  slider!: { height: number; width: number; };
  el: any;
  gridLabel: any;
  constructor(props: {} | Readonly<{}>) {
    super(props);

    // bindings
    this.updateThresholds = this.updateThresholds.bind(this);
    this.resizeDimensions = this.resizeDimensions.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.cleanup = this.cleanup.bind(this);
    this.handleUp = this.handleUp.bind(this);
  };

  updateThresholds() {
    // STUB
  }

  resizeDimensions() {
    // STUB
  }

  componentDidMount() {
    this.el = null;
    this.stash = {
      x: 0,
      y: 0
    };
    this.window = {
      height: 0,
      width: 0
    };
    this.threshold = {
      min: 0,
      max: 0
    };
    this.slider = {
      height: 32,
      width: 32
    };
    this.updateThresholds();
    window.addEventListener('resize', this.resizeDimensions);
  }

  componentWillUnmount() {
    this.cleanup();
    window.removeEventListener('resize', this.resizeDimensions);
  }

  handleDrag(event: any) {
    // STUB
  }

  handleMove(event: any) {
    if (event.type === 'touchmove') {
      event.pageX = event.touches[0].pageX
      event.pageY = event.touches[0].pageY
    }
    this.handleDrag(event);
  }

  handleDown(event: { type: string; pageX: any; pageY: any; changedTouches: { pageY: any; pageX: any; }[]; target: any; }) {
    // console.log('handle down', event);
    // @ TODO
    // See https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
    // See https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent#using_with_addeventlistener_and_preventdefault
    // event.preventDefault();
    try {
      this.updateThresholds();
      if (event.type === 'mousedown') {
        this.stash = {
          x: event.pageX,
          y: event.pageY
        }
      } else if (event.type === 'touchstart') {
        this.stash = {
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY
        }
      } else {
        throw new Error('unhandled event type');
      }
      this.el = this.el || event.target;
      this.slider.height = parseInt(window.getComputedStyle(this.el).height.replace('px', ''));
      this.slider.width = parseInt(window.getComputedStyle(this.el).width.replace('px', ''));
      this.gridLabel = this.el.dataset.gridLabel;
      window.addEventListener('mousemove', this.handleMove);
      window.addEventListener('mouseup', this.handleUp);
      window.addEventListener('touchmove', this.handleMove);
      window.addEventListener('touchend', this.handleUp);
      document.querySelector('.richtext-column-wrapper .expanded-content')!.classList.add('disabled');
      document.querySelector('.main-wrapper .grid-overlay')!.classList.remove('hidden');
      document.querySelector('.main-wrapper .grid-overlay')!.classList.add(this.gridLabel);
    } catch (error) {
      console.log(error);
    }
  }
  
  cleanup() {
    // console.log('cleanup');
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('mouseup', this.handleUp);
    window.removeEventListener('touchmove', this.handleMove);
    window.removeEventListener('touchend', this.handleUp);
    document.querySelector('.richtext-column-wrapper .expanded-content')!.classList.remove('disabled');
    document.querySelector('.main-wrapper .grid-overlay')!.classList.add('hidden');
    document.querySelector('.main-wrapper .grid-overlay')!.classList.remove(this.gridLabel);
    this.stash = {
      x: 0,
      y: 0
    };
  }

  handleUp() {
    // console.log('up');
    this.cleanup();
  }
}

export default SliderBaseComponent;
