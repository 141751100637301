import React from 'react';
import { Link } from 'react-router-dom';
import UserBar from '../components/search/UserBar';

function Help() {
  if (window.location.host === "localhost:3000") {
    return (
      <div className="help-wrapper">
        <UserBar />
        <div className="inner-content">
          <p>Need Help?</p>
          <p>Send us an email: <a href="mailto:help@floit.app">help@floit.app</a></p>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/main/1">Main</Link></li>
            <li><Link to="/search">Search</Link> - (search results)</li>
            <li><Link to="/user-profile">User</Link> - (admin page)</li>
            <li><Link to="/organisation">Organisation</Link> - (admin page)</li>
            <li><Link to="/signin">Sign In</Link></li>
            <li><Link to="/help">Help</Link></li>
            <li><Link to="/flowchart">Flowchart test</Link></li>
            <li><Link to="/reactflow">React.Flow test</Link></li>
            <li><Link to="/listFlowsView">list flows</Link></li>
            <li><Link to="/404">404</Link> - (catch all)</li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="help-wrapper">
        <UserBar />
        <div className="inner-content">
          <p>Need Help?</p>
          <p>Send us an email: <a href="mailto:help@floit.app">help@floit.app</a></p>
        </div>
      </div>
    );
  }
}

export default Help;


