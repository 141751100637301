// USING A TEST PAD AT :: https://etherpad.wikimedia.org/p/padforfloit
// Ref :: https://etherpad.org/doc/v1.8.18/#index_embed-parameters
// search for :: createPad(padID [, text])

// @TODO - if the supplied URL doesn't exist - 404 page?

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RootStateInterface from "../../store/model";
// const api = require('etherpad-lite-client');
// import { api } from 'etherpad-lite-client'; // 

interface Props {
  activeClassName: (input: string) => string;
}

function RichTextColumn({ activeClassName }: Props) {
  // @TODO - this should now come from the database
  // @TODO - when a new flo is created, a corresponding Pad should be created too?
  // const file = "https://etherpad.wikimedia.org/p/padforfloit";

  const activeFlowData = useSelector((state: RootStateInterface) => {
    return state.activeFlow.data;
  });
  const userName = useSelector((state: RootStateInterface): string => {
    try {
        return state.auth.data.user.userName;
    } catch (error) {
      return "unknown"
    }
  });

  function mapParamsToQuerystring(paramsObject: any) {
    return Object.keys(paramsObject).map((key) => {
      return `${key}=${paramsObject[key]}`;
    }).join('&');
  };

  // async function setupEtherpad( padId = "padforfloit" ) {
  //   console.log("setupEtherpad");
  //   /*
  //   1. Check to see if etherpad data exists for the active flow.
  //   2. if it exists, render it.
  //   3. if it doesn't exist create it.
  //   4. then render it.
  //   */

  //   try {
  //   //   // 1. Check to see if etherpad data exists for the active flow
  //   //   // @TODO - this padId will become the uuid for the flow
  //   //   let apikey = "";
  //   //   const apiUrl = "http://localhost:4001/auth";
  //   //   const url = `${apiUrl}/getText?apikey=${apikey}&padID=${padId}`;
  //   //   let response: any = await fetch(url);
  //   //   response = await response.json();
  //   //   console.log(response);
  //   //   if (response !== undefined && response.code === 0) {
  //   //     // {code: 0, message:"ok", data: {text:"Welcome Text"}}
  //   //     // 2. if it exists, render it
  //   //     return padId;
  //   //   } else if (response !== undefined && response.code === 1) {
  //   //     // {code: 1, message:"padID does not exist", data: null}
  //   //     // 3. if it doesn't exist create it.
  //   //     // @TODO - this is a generic pad, we could possibly assign it to a group first?
  //   //     const url = `${apiUrl}/createPad?apikey=${apikey}&padID=${padId}`;
  //   //     let response: any = await fetch(url);
  //   //     response = await response.json();
  //   //     console.log(response);
  //   //     // {code: 0, message:"ok", data: null}
  //   //     // {code: 1, message:"padID does already exist", data: null}
  //   //     // {code: 1, message:"malformed padID: Remove special characters", data: null}
  //   //     if (response.code === 0) {
  //   //       // 4. then render it
  //   //       return padId;
  //   //     } else {
  //   //       throw new Error("Cannot create new pad : ", response);
  //   //     }
  //   //   } else {
  //   //     throw new Error("Cannot check pad exists : ", response);
  //   //   }
  //     const response = await fetch(new URL(`http://localhost:4001/setupEtherpad/${padId}`), { method: 'get' });
  //     if (response.status === 200) {
  //       const data = await response.json();
  //       const padId = data.padId;
  //       // console.log("response");
  //       // console.log(response);
  //       // console.log(data);
  //       return padId;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   if (activeFlowData.id !== undefined) {
  //     setupEtherpad(activeFlowData.id);
  //   }
  // }, [activeFlowData]);

  // Disable etherpad by default by passing nothing to the iframe src url
  let [src, setSrc] = useState('');

  useEffect(() => {
    const params = {
      showChat: false,
      showLineNumbers: false,
      alwaysShowChat: false,
      userName: userName
    };
    // Enable etherpad using src url
    const _hideEtherpad = document.documentElement.classList.contains('no-etherpad');
    if (!_hideEtherpad) {
      const queryString = mapParamsToQuerystring(params);
      // PROXY
      // const url = `${process.env.REACT_APP_FLOIT_BACKEND}/proxy/p`;
      // const url = "http://localhost:4000/proxy/p";
      // HOSTED
      const url = "https://pad.floit.app/p";
      // WIKIMEDIA
      // const url = "https://etherpad.wikimedia.org/p",
      // LOCALHOST
      // const url = "http://localhost:9001/p";
      if (activeFlowData !== undefined && activeFlowData.id !== undefined) {
        // console.log("activeFlowData change", activeFlowData.id);
        setSrc(`${url}/${activeFlowData.id}?${queryString}`);
      }
    }
  }, [activeFlowData, userName]);

  // @ts-ignore @TODO
  function Iframe({ src }) {
    if (src !== '') {
      return <iframe title="etherpad" src={src} width='100%' height='100%'></iframe>;
    } else {
      return <p>No Active Flow Data to Display</p>;
    }
  }

  return (
    <div className={activeClassName("richtext-column-wrapper")}>
      <div className="expanded-content">
        <Iframe src={src} />
      </div>
    </div>
  );
}

export default RichTextColumn;
