import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom';

/*
Shared search bar - for use accross site:

Option to show results underneath - ShowInlineSearchResuls, default to false
Option to show current search value - ShowCurrentSearchValue, default to false
If you pass in ShowCurrentSearchValue, you should pass in the search value as query too. defaults to ""
Optional styling classes can be added with the styleClass prop

import SearchBar from '../components/shared/searchBar';

<SearchBar ShowInlineSearchResuls={true} ShowCurrentSearchValue={true} query={"Test Query String"} />
*/

function Searchbar({ ShowInlineSearchResuls = false, ShowCurrentSearchValue = false, query = "", styleClass = "", hideInputElement = false }) {
  query = query || '';
  const navigate = useNavigate();
  function InlineSearchResuls({ ShowInlineSearchResuls }: { ShowInlineSearchResuls: any }) {
    const uuid = crypto.randomUUID();
    if (ShowInlineSearchResuls === true) {
      return (
        <ul className="searchbar-results">
          <li>0 results</li>
          <li><Link to={`main/${uuid}`}><i>create "New Joiner" flow…</i></Link></li>
        </ul>
      )
    } else {
      return null;
    }
  }

  function InputElement({ ShowCurrentSearchValue, query }: { ShowCurrentSearchValue: boolean, query: string }) {
    const _query = ShowCurrentSearchValue === true ? query : "";
    const [searchTerm, setSearchTerm] = useState(_query);

    if (hideInputElement === true) {
      return null;
    } else {
      function handleChange(event: any) {
        setSearchTerm(event.target.value);
      }

      function handleSubmit(event: any) {
        const test1 = event.key === 'Enter' ? true : false;
        const test2 = event.target.value !== "";
        if (test1 && test2) {
          navigate(`/search?q=${event.target.value}`);
        }
      }

      return (
        <input className="search-input" type="text" placeholder="Search for a flow…" value={searchTerm} onChange={handleChange} onKeyUp={handleSubmit}></input>
      )
    }
  }

  const iconStyle = {
    color: 'action.active',
    mr: 1,
    my: 0.5,
    '&:hover': {
      color: 'black'
    }
  };

  return (
    <div className={`search-input-wrapper ${styleClass}`}>
      <div className="search-bar">
        <Link to={"/listflows"} ><SearchIcon sx={iconStyle} /></Link>
        <InputElement ShowCurrentSearchValue={ShowCurrentSearchValue} query={query} />
      </div>
      <InlineSearchResuls ShowInlineSearchResuls={ShowInlineSearchResuls} />
    </div>
  );
}

export default Searchbar;
