import React from 'react';
import UserBar from '../components/search/UserBar';
import SearchBar from '../components/shared/SearchBar';
import ResultsList from '../components/search/ResultsList';
import { useSelector, useDispatch } from 'react-redux';
import { createChildForRootFlow } from '../store';
import Button from '@mui/material/Button';
import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import Prompt from '../components/shared/Prompt';

function ListFlowsView() {
  const dispatch = useDispatch();
  const data = useSelector((state: any) => {
    return state.rootFlow.data;
  });

  // const { isLoaded, isSignedIn, user } = useUser();

  // if (!isLoaded || !isSignedIn) {
  //   return null;
  // }

  // console.log(user.id);

  // const myOrgId = user.organizationMemberships[0];
  // console.log(myOrgId);
  // if (myOrgId !== undefined) {
  //   // @ts-ignore
  //   const action = authenticateUser({ userId })
  //   // @ts-ignore
  //   dispatch(action);
  // }


  function handleNewFlow(name: string | null) {
    // console.log("handleNewFlow");
    // const name = prompt("what is the name of the new flow?");
    if (name !== "" && name !== null) {
      // @ts-ignore
      const action = createChildForRootFlow({ name });
      // @ts-ignore
      dispatch(action);
    }
  }

  return (

    <div className="search-wrapper">
      <SignedIn>
        <UserBar />
        <SearchBar ShowCurrentSearchValue={false} styleClass="style-large" />
        <Prompt message={"what is the name of the new flow?"} defaultValue={''} callback={handleNewFlow} >
          <Button variant="outlined">New Flow</Button>
        </Prompt>
        <ResultsList results={data} />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </div>
  );
}

export default ListFlowsView;
