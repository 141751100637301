

import React, { useState } from "react";
import { OrganizationProfile, UserProfile } from '@clerk/clerk-react'
import UserBar from '../components/search/UserBar'
import { Button } from "@mui/material";


function UserOrgView() {
  const [order, setOrder] = useState("UserProfile");
  let Content;
  if (order === "UserProfile") {
    Content = () => {
      return (
        <>
          <div id="UserProfile">
            <UserProfile />
          </div>
          <div id="OrganizationProfile">
            <OrganizationProfile />
          </div>
        </>
      );
    }
  } else {
    Content = () => {
      return (
        <>
          <div id="OrganizationProfile">
            <OrganizationProfile />
          </div>
          <div id="UserProfile">
            <UserProfile />
          </div>
        </>
      );
    }
  }

  function testOrder(arg: string): "contained" | "outlined" {
    return order === arg ? "contained" : "outlined";
  }

  return (
    <div className="user-wrapper">
      <UserBar />
      <div className="inner-content">
        <div className="button-wrapper">
          <Button variant={testOrder("UserProfile")} onClick={() => setOrder("UserProfile")}>Personal Profile</Button>
          <Button variant={testOrder("OrganizationProfile")} onClick={() => setOrder("OrganizationProfile")}>Organisation Profile</Button>
        </div>
        <Content />
      </div>
    </div>
  )
}

export default UserOrgView