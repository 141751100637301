/* FlOit comment model */

/** Vote direction */
// Note kept numeric so they can easily be summed
export type VoteDirection = -1 | 1;

/** Record of a vote by a particular user */
export class Vote {
  /** Graph ID of the vote */
  public readonly id: string;

  /** User ID that votes */
  public readonly userId: string;

  /** Direction they voted - +1 or -1 */
  public readonly direction: VoteDirection;

  /** Constructor */
  constructor(id: string, userId: string, direction: VoteDirection) {
    this.id = id;
    this.userId = userId;
    this.direction = direction;
  }
}

/** Tree of comments associated with a Flow */
export default class Comment {
  /** Graph ID of the comment */
  public readonly id: string;

  /** UserId who created the comment */
  public readonly userName: string;

  /** Content of the comment */
  public readonly text: string;

  /** Date of the comment */
  public readonly date: string;

  /** Current upvote count */
  public readonly upvotes: number;

  /** Current downvote count */
  public readonly downvotes: number;

  /** Current vote from this user */
  public readonly thisUserHasVoted: number;

  /** Children comments */
  public discussion: Array<Comment>;

  /** Constructor */
  constructor(id: string, userName: string, text: string, date: string, upvotes: number, downvotes: number, thisUserHasVoted: number, discussion: any) {
    this.id = id;
    this.userName = userName;
    this.text = text;
    this.date = date;
    // @TODO FIX and remove ||
    this.upvotes = upvotes || 0;
    // @TODO FIX and remove ||
    this.downvotes = downvotes || 0;
    // @TODO FIX and remove ||
    this.thisUserHasVoted = thisUserHasVoted || 0;
    this.discussion = discussion;
  }

  public static readFromGraphQL(graphComments: Array<any>): Array<Comment> {
    const topLevelComments = graphComments.map((gcomment: any) => {
      let result: any = null;
      if (gcomment.parent === null) {
        const discussion = gcomment.children.map((el: any) => el.id);
        // console.log(gcomment);
        const comment = new Comment(gcomment.id, gcomment.user.name, gcomment.text, gcomment.userConnection.edges[0].on, gcomment.upvotes, gcomment.downvotes, gcomment.thisUserHasVoted, discussion);
        // console.log(comment);        
        result = comment;
      }
      return result;
    }).filter((el) => {
      return el !== null;
    });
    // console.log(topLevelComments);

    const remainingComments = graphComments.filter((gcomment: any) => {
      let result: any = null;
      if (gcomment.parent !== null) {
        const discussion = gcomment.children.map((el: any) => el.id);
        const comment = new Comment(gcomment.id, gcomment.user.name, gcomment.text, gcomment.userConnection.edges.on, gcomment.upvotes, gcomment.downvotes, gcomment.thisUserHasVoted, discussion);
        result = comment;
      }
      return result;
    }).filter((el) => {
      return el !== null;
    });
    // console.log(remainingComments);

    remainingComments.forEach((comment) => {
      // console.log(comment);
      
      const parent = findParentComment(comment, topLevelComments)[0];
      // console.log("child ", comment.id, " parent ", parent.id);
      // console.log(parent);
      const discussion = comment.children.map((el: any) => el.id);
      const _comment = new Comment(comment.id, comment.user.name, comment.text, comment.userConnection.edges.on, comment.upvotes, comment.downvotes, comment.thisUserHasVoted, discussion);
      
      parent.discussion.push(_comment);
      const index = parent.discussion.indexOf(comment.id);
      // console.log(index);
      parent.discussion.splice(index, 1);
      // console.log(parent);
    });

    // @ts-ignore
    function findParentComment(comment, comments) {
      // console.log("looking for ", comment.id);

      const result = comments.filter((_comment: any) => {
        // console.log(_comment);
        let result;
        if (_comment === comment.id) {
          result = _comment
        } else if (_comment.discussion !== undefined && _comment.discussion.length > 0) {
          result = findParentComment(comment, _comment.discussion);
        } else {
          result = false;
        }
        return result;
      });
      // console.log(comment);
      return result
    }
    // console.log(topLevelComments);
    // console.log(remainingComments);

    return JSON.parse(JSON.stringify(topLevelComments));
  }
};







