import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GraphQLFlowDataProvider from '../data/GraphQLFlowDataProvider';
import GraphQLCommentDataProvider from '../data/GraphQLCommentDataProvider'
import { bind, enhanceReducer } from 'redux-yjs-bindings';
import { Doc } from 'yjs';
import { wsProvider, newWebsocket } from '../data/websocketDataProvider';

// import _authenticateUserData from '../data/authentication.json';

// 'http://localhost:4000/'
let apolloURL = process.env.REACT_APP_FLOIT_BACKEND;
// 'ws://localhost:4000/'
let websocketURL = apolloURL.replace('http', 'ws') || process.env.REACT_APP_FLOIT_WEBSOCKET_BACKEND;
console.log(` apolloURL : ${apolloURL}\n websocketURL : ${websocketURL}`);

/* authentication START */
const initialAuthenticationState = {
  data: { user: {}, org: {} },
  status: 'idle',
  error: null
}

const authenticateUser = createAsyncThunk('auth/authLogin', async (params, thunkAPI) => {
  // @HERE - params to this point seem OK
  console.log('authenticateUser ', params)
  const { userName, userId, orgId, orgName } = params;
  const setProvider = new GraphQLFlowDataProvider(apolloURL);
  let userData
  if (setProvider) {
    try {
      userData = await setProvider.getUserById({ userName, userId, orgId, orgName });
    }
    catch (e) {
      console.log("User Data fetch failed: " + e);
    }
  }
  await thunkAPI.dispatch(fetchRootFlow({ organisationId: orgId }));
  return userData;
});

const authenticationSlice = createSlice({
  name: 'auth',
  initialState: initialAuthenticationState,
  reducers: {
    authLogout(state, action) {
      return initialAuthenticationState;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(authenticateUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});
/* authentication END */

/* rootFlow START */
const initialRootFlowState = {
  data: {},
  status: 'idle',
  error: null
}

function mapFlowToJSON(flow) {
  return (
    {
      id: flow.id,
      name: flow.name,
      children: flow.children,
      comments: flow.comments
    }
  );
}

function mapChildtoJSON(flow) {
  // console.log(flow);
  if (flow.children.length > 0) {
    return (
      {
        id: flow.id,
        name: flow.name,
        children: flow.children.map((child) => {
          return mapChildtoJSON(child);
        }),
        comments: flow.comments
      }
    );
  } else {
    return (
      {
        id: flow.id,
        name: flow.name,
        children: flow.children,
        comments: flow.comments
      }
    );
  }
}

async function mapRootFlowToJSON(rootFlow) {
  try {
    let data = rootFlow.children.map((flow) => {
      // console.log(flow);
      return mapFlowToJSON(flow);
    });

    // console.log(data);
    data.forEach((topLevelFlow) => {
      topLevelFlow.children = topLevelFlow.children.map((child) => {
        return mapChildtoJSON(child);
      });
    });
    // console.log(data);

    return data;
  } catch (error) {
    console.log("mapRootFlowToJSON FAILED", error);
  }
}

const fetchRootFlow = createAsyncThunk('rootFlow/fetchRootFlow', async ({ organisationId }, thunkAPI) => {
  // console.log("fetchRootFlow", organisationId);
  if (organisationId === undefined) {
    const _state = await thunkAPI.getState();
    organisationId = _state.auth.data.org.organisationId;
  }
  // console.log(organisationId);
  const setProvider = new GraphQLFlowDataProvider(apolloURL);
  if (setProvider) {
    try {
      let rootFlow = await setProvider.getRootFlow(organisationId);
      // console.log(rootFlow);
      rootFlow = await mapRootFlowToJSON(rootFlow);
      return JSON.parse(JSON.stringify(rootFlow));
    }
    catch (e) {
      console.log("Root flow fetch failed: " + e);
    }
  }
});

const createChildForRootFlow = createAsyncThunk('rootFlow/addChild', async (createFlowParams, thunkAPI) => {
  try {
    console.log("createChildForRootFlow", createFlowParams);
    const provider = new GraphQLFlowDataProvider(apolloURL);
    const { name } = createFlowParams;
    const _state = await thunkAPI.getState();
    console.log(_state.auth)
    const organisationId = _state.auth.data.org.organisationId;
    const userId = _state.auth.data.user.userId;

    const params = {
      organisationId,
      userId,
      name,
      parentId: null
    }
    const newFlowId = await provider.createChildFlow(params);

    console.log(`Created flow ${JSON.stringify(newFlowId)}`);
    const action = fetchRootFlow({ organisationId });
    thunkAPI.dispatch(action);
  } catch (e) {
    console.error("Flow creation - Child for Root Flow - failed: " + e);
  }
});

const deleteChildForRootFlow = createAsyncThunk('rootFlow/removeChild', async (deleteFlowId, thunkAPI) => {
  try {
    const _state = await thunkAPI.getState();
    const organisationId = _state.auth.data.org.organisationId;
    const provider = new GraphQLFlowDataProvider(apolloURL);
    await provider.deleteFlow(deleteFlowId);
    const action = fetchRootFlow({ organisationId });
    thunkAPI.dispatch(action);
  } catch (e) {
    console.error("Flow deletion failed: " + e);
  }
});

const rootFlowSlice = createSlice({
  name: 'rootFlow',
  initialState: initialRootFlowState,
  reducers: {
    clearRootFlow(state, action) {
      return initialRootFlowState
    },
    updateRootFlow(state, action) {
      const { title, content } = action.payload;
      state.rootFlow.title = title;
      state.rootFlow.content = content;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRootFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchRootFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchRootFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createChildForRootFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createChildForRootFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createChildForRootFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteChildForRootFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteChildForRootFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(deleteChildForRootFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});
/* rootFlow END */

/* openFlow START */
const fetchOpenFlow = createAsyncThunk('openFlow/fetchOpenFlow', async (fetchFlowParams) => {
  try {
    const provider = new GraphQLFlowDataProvider(apolloURL);
    const { id } = fetchFlowParams;
    if (id === undefined) {
      throw new Error("'id' is undefined");
    }
    // console.log("Looking for ", id);
    if (id !== undefined) {
      const flow = await provider.getFlow(id);
      // console.log(wsProvider);
      if (wsProvider === undefined) {
        // console.log("Opening WebSocket");
        newWebsocket(websocketURL, id, yDoc);
      } else if (wsProvider.roomname === id) {
        // console.log("web socket : no change");
      } else if (wsProvider.roomname !== id) {
        // THIS SHOULD NEVER HAPPEN
        // console.log("closing web socket, reopening with new ID");
        newWebsocket(websocketURL, id, yDoc);
      }

      return flow;
    }
  } catch (e) {
    console.error("Fetch Open Flow failed: " + e);
  }
});

const createChildForOpenFlow = createAsyncThunk('openFlow/addChild', async (createFlowParams, thunkAPI) => {
  console.log("createChildForOpenFlow");
  try {
    const provider = new GraphQLFlowDataProvider(apolloURL);
    const { name } = createFlowParams;
    const _state = await thunkAPI.getState();
    const organisationId = _state.auth.data.org.organisationId;
    const userId = _state.auth.data.user.userId;
    const openFlowId = _state.openFlow.data.id;
    let preceedingFlowID;
    let newFlowId;
    const flowHasChildren = _state.openFlow.data.children.length > 0;
    if (flowHasChildren) {
      preceedingFlowID = _state.openFlow.data.children[_state.openFlow.data.children.length - 1].id;
      const params = {
        organisationId,
        userId,
        name,
        afterSiblingId: preceedingFlowID
      }
      newFlowId = await provider.createFlow(params);
    } else {
      preceedingFlowID = 0;
      const params = {
        organisationId,
        userId,
        name,
        parentId: openFlowId
      }
      newFlowId = await provider.createChildFlow(params);
    }
    const newFlow = {
      id: newFlowId,
      name,
      children: [],
      comments: []
    };
    console.log(`Created flow ${JSON.stringify(newFlow)}`);
    // console.log('one');
    const action = appendChildToOpenFlow(newFlow);
    thunkAPI.dispatch(action);
    // console.log('two');
    // const actionTwo = fetchRootFlow({organisationId});
    // thunkAPI.dispatch(actionTwo);
    // console.log('three');
    return newFlowId;
  } catch (e) {
    console.error("Flow creation - Child for Open Flow - failed: " + e);
  }
});

// $userId: ID, $organisationId: ID, $name: String, $parentId: ID
const createGrandchildForOpenFlow = createAsyncThunk('openFlow/addGrandchild', async (createFlowParams, thunkAPI) => {
  try {
    const provider = new GraphQLFlowDataProvider(apolloURL);
    const { name, parentId } = createFlowParams;
    const _state = await thunkAPI.getState();
    const organisationId = _state.auth.data.org.organisationId;
    const userId = _state.auth.data.user.userId;
    const params = {
      organisationId,
      userId,
      name,
      parentId
    }
    await provider.createChildFlow(params);
    const id = _state.openFlow.data.id;
    // let _openFlow = _state.rootFlow.data.filter((flow) => {
    //   return flow.id === id;
    // });
    // // @TODO consider making a generic "fetchOpenFlow(id)" method
    // _openFlow = JSON.parse(JSON.stringify(_openFlow[0]));
    // _openFlow.children.push(newFlow);
    const action = fetchOpenFlow({ id });
    await thunkAPI.dispatch(action);
    // const actionTwo = fetchRootFlow();
    // thunkAPI.dispatch(actionTwo);
  } catch (e) {
    console.error("Flow Grandchild creation failed: " + e);
  }
});

const reorderChildForOpenFlow = createAsyncThunk('openFlow/reorderChild', async ({ flowId, afterSiblingId }, thunkAPI) => {
  try {
    const _state = await thunkAPI.getState();
    const openFlowId = _state.openFlow.data.id;
    const provider = new GraphQLFlowDataProvider(apolloURL);
    console.log(`Moving ${flowId} , child of ${openFlowId} after ${afterSiblingId}`);
    await provider.moveFlow(flowId, openFlowId, afterSiblingId);
    const action = fetchOpenFlow({ id: openFlowId });
    thunkAPI.dispatch(action);
  } catch (e) {
    console.error("Flow renaming failed: " + e);
  }
});

const renameChildForOpenFlow = createAsyncThunk('openFlow/renameChild', async ({ flowId, name }, thunkAPI) => {
  try {
    // console.log(`renaming ${flowId} as ${name} :: renameChildForOpenFlow`);
    const provider = new GraphQLFlowDataProvider(apolloURL);
    await provider.renameFlow(flowId, name);
    // @TODO - this action/dispatch is not required
    // const action = fetchActiveFlow({ id: flowId, setOpenFlow: "parent" });
    // thunkAPI.dispatch(action);
  } catch (e) {
    console.error("Flow renaming failed: " + e);
  }
});

const deleteChildForOpenFlow = createAsyncThunk('openFlow/removeChild', async (deleteFlowId, thunkAPI) => {
  try {
    const _state = await thunkAPI.getState();
    const openFlowId = _state.openFlow.data.id;
    const provider = new GraphQLFlowDataProvider(apolloURL);
    await provider.deleteFlow(deleteFlowId);
    const action = fetchOpenFlow({ id: openFlowId });
    thunkAPI.dispatch(action);
  } catch (e) {
    console.error("Flow deletion failed: " + e);
  }
});

const initialOpenFlowState = {
  data: {},
  status: 'idle',
  error: null
}

const openFlowSlice = createSlice({
  name: 'openFlow',
  initialState: initialOpenFlowState,
  reducers: {
    // setOpenFlow: {
    //   reducer(state, action) {
    //     state.status = "succeeded"
    //     state.data = action.payload;
    //   }
    // },
    clearOpenFlow: {
      reducer(state, action) {
        return initialOpenFlowState;
      }
    },
    appendChildToOpenFlow: {
      reducer(state, action) {
        state.data.children.push(action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createChildForOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createChildForOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createChildForOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(renameChildForOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(renameChildForOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(renameChildForOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteChildForOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteChildForOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(deleteChildForOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createGrandchildForOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createGrandchildForOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createGrandchildForOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(reorderChildForOpenFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(reorderChildForOpenFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(reorderChildForOpenFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});
/* openFlow END */

/* activeFlow START */
const initialActiveFlowState = {
  data: {},
  status: 'idle',
  error: null
}

const fetchActiveFlow = createAsyncThunk('activeFlow/fetchFlow', async (fetchFlowParams, thunkAPI) => {
  const provider = new GraphQLFlowDataProvider(apolloURL);
  const { id, setOpenFlow } = fetchFlowParams;
  if (id === undefined) {
    throw new Error("'id' is undefined");
  }
  if (setOpenFlow === undefined) {
    throw new Error("setOpenFlow must be 'parent' or 'self'");
  }
  if (provider && id !== undefined) {
    try {
      const flow = await provider.getFlow(id);
      let comments = await provider.getCommentsFor(id);
      flow.discussion = comments;
      if (setOpenFlow === "parent") {
        await thunkAPI.dispatch(fetchOpenFlow({ id: flow.parentId }));
      } else if (setOpenFlow === "self") {
        await thunkAPI.dispatch(fetchOpenFlow({ id }));
      }
      return flow;
    }
    catch (e) {
      console.error("Fetch Active Flow failed: " + e);
    }
  }
});

const createActiveFlowComment = createAsyncThunk('activeFlow/addComment', async (params, thunkAPI) => {
  const commentProvider = new GraphQLCommentDataProvider(apolloURL);
  try {
    const { flowId, commentId, text, displayStyle } = params;
    const state = await thunkAPI.getState();
    const activeFlowId = state.activeFlow.data.id;
    const userId = state.auth.data.user.userId;
    let _comment = await commentProvider.createComment(flowId, commentId, text, userId);
    if (_comment === "success") {
      thunkAPI.dispatch(fetchActiveFlow({ id: activeFlowId, setOpenFlow: displayStyle }));
    } else {
      throw new Error('could not comment on flow');
    }
  }
  catch (e) {
    console.log("create Active Flow Comment failed: " + e);
  }
});

const createActiveFlowCommentVote = createAsyncThunk('activeFlow/addCommentVote', async (params, thunkAPI) => {
  const commentProvider = new GraphQLCommentDataProvider(apolloURL);
  try {
    const state = await thunkAPI.getState();
    const activeFlowId = state.activeFlow.data.id;
    const userId = state.auth.data.user.userId;
    const { commentId, choice } = params;
    const value = choice;
    // console.log(`voteOnComment ${activeFlowId} , ${commentId} , ${value}, ${userId}`);
    let _vote = await commentProvider.voteOnComment(commentId, value, userId);
    if (_vote === "success") {
      thunkAPI.dispatch(fetchActiveFlow({ id: activeFlowId }));
    } else {
      throw new Error('could not vote on comment');
    }
  } catch (e) {
    console.log("create Active Flow Comment Vote failed: " + e);
  }
});

const activeFlowSlice = createSlice({
  name: 'activeFlow',
  initialState: initialActiveFlowState,
  reducers: {
    updateActiveFlow: {
      reducer(state, action) {
        const { title, content } = action.payload
        state.activeFlow.title = title
        state.activeFlow.content = content
      }
    },
    clearActiveFlow: {
      reducer(state, action) {
        return initialActiveFlowState;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveFlow.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchActiveFlow.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchActiveFlow.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createActiveFlowComment.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createActiveFlowComment.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createActiveFlowComment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createActiveFlowCommentVote.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createActiveFlowCommentVote.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createActiveFlowCommentVote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});
/* activeFlow END */

// Create Yjs document, or use existing one.
const yDoc = new Doc();

/* STORE */
const store = configureStore({
  reducer: {
    auth: authenticationSlice.reducer,
    rootFlow: rootFlowSlice.reducer,
    openFlow: enhanceReducer(openFlowSlice.reducer),
    activeFlow: activeFlowSlice.reducer
  }
});

// Start synchronisation of Redux and Yjs
bind(yDoc, store, 'openFlow');

// testState();
function testState() {
  const _state = store.getState()
  console.log(_state);
}

export const { authLogout } = authenticationSlice.actions;
export const { clearRootFlow, updateRootFlow } = rootFlowSlice.actions;
export const { setOpenFlow, clearOpenFlow, appendChildToOpenFlow, removeChildFromOpenFlow } = openFlowSlice.actions;
export const { updateActiveFlow, clearActiveFlow } = activeFlowSlice.actions;
export { store, authenticateUser, fetchRootFlow, createChildForRootFlow, deleteChildForRootFlow, fetchOpenFlow, createChildForOpenFlow, createGrandchildForOpenFlow, renameChildForOpenFlow, reorderChildForOpenFlow, deleteChildForOpenFlow, fetchActiveFlow, createActiveFlowComment, createActiveFlowCommentVote, testState };

/*
// Get state data from redux
import { useSelector } from 'react-redux';
…
  const authUserData = useSelector((state) => {
    return state.auth.data.user;
  });
  // console.log(authUserData);

// Add/Update data in redux
import { useDispatch } from 'react-redux';
import { authLogin } from '../store/index.ts';
…
const dispatch = useDispatch();
…
const action = authLogin(data);
dispatch(action);

// Show Current State (for debugging)
import { testState } from '../store/index.ts';
testState();
*/