// Example flowchart page fed from GraphQL model
import React, { useEffect, useState } from 'react';
import FlowDataProvider from '../data/FlowDataProvider';
import GraphQLFlowDataProvider from '../data/GraphQLFlowDataProvider';

// URL for Apollo server (floit-backend)
const apolloURL = "http://localhost:4000";
const organisationId = "19c86076-8467-4313-8c0d-6e9a58866613";


// Flowchart view props
type FlowchartViewProps = {
};

// Flowchart view
const FlowchartView: React.FunctionComponent<FlowchartViewProps> = () => {
  const [provider, setProvider] = useState<FlowDataProvider | null>(null);
  const [content, setContent] = useState("Nothing Here");

  // Create data provider
  useEffect(() => {
    // Note: Switch this for another one if you want
    setProvider(new GraphQLFlowDataProvider(apolloURL));
    // eslint-disable-next-line
  }, [apolloURL]);

  // Fetch data on mount
  useEffect(() => {
    const fetch = async () => {
      if (provider) {
        try {
          const flow = await provider.getRootFlow(organisationId);
          // const generator = new CytoscapeFlowchartGenerator();
          // setCytoscapeModel(generator.generateFrom(flow));
          console.log(flow);
          
          setContent(JSON.stringify({"result" : "TEST"}));
        }
        catch (e) {
          console.error("Root flow fetch failed: " + e);
        }
      }
    };

    fetch();
  }, [provider]);

  return (
    <div className="flowchart-wrapper" style={{ textAlign: 'left' }}>
      <h1>Hello, Darren</h1>
      <div>{content}</div>
    </div>
  );
};

export default FlowchartView;
