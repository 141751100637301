/** flOit React.Flow edges generator */
import Flow from './flow';

export default class ReactflowEdgeGenerator {
  /** Generate edges for React.Flow from a root flow */
  public generateFrom(root: Flow): Array<any> {
    function addChildrenWithParent(root: Flow, parent: Flow | null,
      elements: Array<any>) {
      for (const flow of root.children) {
        elements.push({
          id: "placeholder",
          source: flow.id,
          target: flow.next,
          label: "Followed by"
        });

        // Recurse
        if (flow.children.length)
          addChildrenWithParent(flow, flow, elements);
      }
    };

    const elements: Array<any> = [];
    addChildrenWithParent(root, null, elements);
    return elements;
  }
}
