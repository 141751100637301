import React from 'react';
import SliderBaseComponent from './SliderBaseComponent';
import HeightIcon from '@mui/icons-material/Height';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

class SliderVertical extends SliderBaseComponent {
  root: any;
  measurements: any;

  constructor(props: any) {
    super(props);
    this.handleDown = super.handleDown;
    this.root = document.querySelector(':root');
    this.measurements = {};
    this.resizeDimensions();
  };

  resizeDimensions() {
    const coefficient = parseInt(getComputedStyle(this.root).getPropertyValue('--main-grid-width-col-editor')) || 0;
    const hundredPercent = window.innerWidth;
    // @TODO: this 32px is the width of the slider and I think it should not be hard coded
    const discountedWidth = 32 + parseInt(getComputedStyle(this.root).getPropertyValue('--main-grid-width-col-list'));
    const remainingWidth = hundredPercent - discountedWidth;
    this.measurements = {
      coefficient,
      discountedWidth,
      remainingWidth
    }
  }

  handleDrag(event: { pageX: any; }) {
    if (event.pageX > this.measurements.discountedWidth) {
      const correctedPageX = event.pageX - this.measurements.discountedWidth;
      let fraction = Math.round((correctedPageX / this.measurements.remainingWidth) * 10) / 10;
     
      fraction = fraction <= 0 ? 0 : fraction;
      fraction = fraction >= 1 ? 1 : fraction;

      if (fraction !== this.measurements.coefficient) {
        // console.log(`change to ${fraction}`);
        this.root.style.setProperty('--main-grid-width-col-editor', fraction);
        this.measurements.coefficient = fraction;
      }
    }
  }

  expandTo(number: number) {
    this.root.style.setProperty('--main-grid-width-col-editor', number);
    this.measurements.coefficient = number;
  }

  render() {
    return (
      <div
        className="slider slider-vertical"
        onMouseDown={(event: any) => this.handleDown(event)}
        onTouchStart={(event: any) => this.handleDown(event)}
        data-grid-label="grid-vertical"
      >
        <ArrowRightAltIcon onClick={() => {this.expandTo(0)}} style={{ transform: "rotate(180deg)" }} />
        <HeightIcon style={{ transform: "rotate(90deg)" }} />
        <ArrowRightAltIcon onClick={() => {this.expandTo(1)}} />
      </div>
    )
  }
}

export default SliderVertical;