import React from 'react';
import { useSelector } from 'react-redux';
import RootStateInterface from '../../store/model';
import DiscussionBarItems from './discussionBar/DiscussionBarItems';
import NewDiscussion from './discussionBar/NewDiscussion';

interface Props {
  activeClassName: (input: string) => string;
}

function DiscussionBar({ activeClassName }: Props) {
  try {
    const activeFlowData = useSelector((state: RootStateInterface) => {
      return state.activeFlow.data;
    });
    // console.log(activeFlowData);

    const discussion = activeFlowData.discussion;

    if (discussion === undefined) {
      return (
        <div className={activeClassName("discussion-bar-wrapper")}>
          <div className="expanded-content">
            <p>NO DATA TO DISPLAY</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={activeClassName("discussion-bar-wrapper")}>
          <div className="expanded-content">
            <ul className="discussion-list" data-parent-type={"flow"}>
              <DiscussionBarItems data={discussion} />
              <NewDiscussion parentId={activeFlowData.id} />
            </ul>
          </div>
        </div>
      );
    }
  } catch (error) {
    return (
      <div className={activeClassName("discussion-bar-wrapper")}>
        <div className="expanded-content">
          <p>Non-descript Error</p>
        </div>
      </div>
    )
  }
}

export default DiscussionBar;