import { WebsocketProvider } from 'y-websocket'

let wsProvider: any;

function newWebsocket(websocketURL: string, id: string, yDoc: any) {
    wsProvider = new WebsocketProvider(websocketURL, id, yDoc);
    // wsProvider.on('status', (event: any) => {
    //     console.log('WebSocket :', event.status) // logs "connected" or "disconnected"
    // });
}

function cleanWebsocket() {
    wsProvider = undefined;
}

function closeWebsocketSync() {
    return new Promise((resolve) => {
        if (wsProvider !== undefined && wsProvider.destroy !== undefined) {
            wsProvider.disconnect();
            wsProvider.destroy();
            wsProvider.on('connection-close', () => {
                //   console.log("Closed", WSClosedEvent);
                cleanWebsocket();
                resolve("");
            });
        } else {
            resolve("");
        }
    });
}

export { wsProvider, newWebsocket, closeWebsocketSync }