// Example React.Flow page fed from GraphQL model

import React, { useEffect, useState } from 'react';
import GraphQLFlowDataProvider from '../data/GraphQLFlowDataProvider';
import ReactflowNodeGenerator from '../model/reactflow';
import ReactflowEdgeGenerator from '../model/reactflowEdge';
import ReactFlow, { Background, Controls } from 'reactflow';

// React.Flow no display if width & height unspecified
import 'reactflow/dist/style.css';
// choice between putting css in this folder, or among SCSS in styles subfolder; chose former temporarily
import './reactflow.css';  // import '../styles/views/main/reactflow.css';

function ReactFlowViewLive() {
  // URL for Apollo server (floit-backend)
  const apolloURL = "http://localhost:4000";
  const organisationId = "19c86076-8467-4313-8c0d-6e9a58866613";

  const [reactflowModel, setReactflowModel] = useState([]);
  const [provider, setProvider] = useState(null);

  // Create data provider
  useEffect(() => {
    setProvider(new GraphQLFlowDataProvider(apolloURL));
  }, []);

  // Fetch data on mount
  useEffect(() => {
    const fetch = async () => {
      if (provider) {
        try {
          const flow = await provider.getRootFlow(organisationId);

          const nodeGenerator = new ReactflowNodeGenerator();
          let nodes = nodeGenerator.generateFrom(flow);

          // @TODO - this is a quick workaround (Huw)
          nodes = nodes.filter((node) => {
            return node.position !== undefined;
          });
          console.log('Nodes:', nodes);

          // code from React.Flow tutorial not usable here
          // const [nodes, setNodes] = useState(initialNodes);

          // also not usable because setNodes not acquired from useState()
          // const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);

          const edgeGenerator = new ReactflowEdgeGenerator();
          let edges = edgeGenerator.generateFrom(flow);
          console.log('Edges:', edges);

          setReactflowModel(nodes);
        }
        catch (e) {
          console.error("Root flow fetch failed: " + e);
        }
      }
    };

    fetch();
  }, [provider]);

  if (provider === null) {
    console.log('WAITING FOR DATA');
    return null;
  } else {
    return (
      <div className="reactflow-wrapper" style={{ height: '100%', width: '100%' }}>
        <ReactFlow nodes={reactflowModel}>
          <Background />
          <Controls />
        </ReactFlow>
        {/* <Button variant="contained" onClick={create}>Create</Button>
        <Button variant="contained" onClick={rename}>Rename</Button> */}
      </div>
    );
  }
};

export default ReactFlowViewLive;
