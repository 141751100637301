
import _data from '../data/searchResults.json';

async function searchApi(searchTerm: string, callback: any) {
  if (window.location.hostname === 'localhost') {
    setTimeout(() => {
      callback(_data);
    }, 700)
  } else {
    const url = '';
    fetch(url + '?' + searchTerm)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        callback(data);
      });
  }
}

export default searchApi;