import React from 'react';
import { Link } from 'react-router-dom';


function Page404() {
  return (
    <div className="page404-wrapper">
      <h1>Ooops, that's an error</h1>
      <div>
        <p>Maybe try the <Link to="/help">Help</Link> page?</p>
        <p>Or send us an email; <a href="mailto:help@floit.app">help@floit.app</a>
        </p>
      </div>
    </div>
  )
}

export default Page404;