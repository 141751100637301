import React from 'react';
import { useSelector } from 'react-redux';
import RootStateInterface from '../../store/model';

function LoadingOverlay() {
  const openFlowStatus = useSelector((state: RootStateInterface) => {
    return state.openFlow.status;
  });
  // console.log(openFlowStatus);

  if (openFlowStatus === 'loading') {
    return <div className="page-overlay loading">Loading</div>
  } else if (openFlowStatus === 'failed') {
    return <div className="page-overlay error">Error, loading failed</div>
  } else {
    // openFlowStatus === 'succeeded'
    return null
  }
}

export default LoadingOverlay;
