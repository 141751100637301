import React, { useState } from 'react';

import UserBar from '../components/main/UserBar';
import ListColumn from '../components/main/ListColumn';
import RichTextColumn from '../components/main/RichTextColumn';
import FlowChartColumn from '../components/main/FlowChartColumn';
import DiscussionBar from '../components/main/DiscussionBar';
import SliderHorizontal from '../components/main/SliderHorizontal';
import SliderVertical from '../components/main/SliderVertical';
import SmallStateManager from '../components/main/SmallStateManager';
import GridOverlay from '../components/main/GridOverlay';
import LoadingOverlay from '../components/main/LoadingOverlay';


function Main() {
  
  // import { useParams } from 'react-router-dom';
  // let { urlOpenFlowId, urlActiveFlowId } = useParams();
  // console.log(`Open flow should be: ${urlOpenFlowId}\nActive flow should be: ${urlActiveFlowId}`);
  
  // PARENT element to keep track of small view "active" tab
  const [smallActiveView, setSmallActiveView] = useState("list-column-wrapper");
  function smallActiveClassName(elementName: string): string {
    const activeName = smallActiveView;
    if (activeName === elementName) {
      return elementName + ' active';
    } else {
      return elementName + ' small-hide-view';
    }
  }

  return (
    <div className="main-wrapper">
      <LoadingOverlay />
      <UserBar />
      <SmallStateManager smallActiveView={smallActiveView} setSmallActiveView={setSmallActiveView} />
      <ListColumn activeClassName={smallActiveClassName} />
      <RichTextColumn activeClassName={smallActiveClassName} />
      <SliderVertical />
      <FlowChartColumn activeClassName={smallActiveClassName} />
      <SliderHorizontal />
      <DiscussionBar activeClassName={smallActiveClassName} />
      <GridOverlay />
    </div>
  )
}

export default Main;
