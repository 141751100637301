/** flOit React.Flow chart generator */
import Flow from './flow';

export default class ReactflowNodeGenerator {
  /** Generate nodes for React.Flow from a root flow */
  public generateFrom(root: Flow): Array<any> {
    function addChildrenWithParent(root: Flow, parent: Flow | null,
      elements: Array<any>) {
      for (const flow of root.children) {
        elements.push({
          id: flow.id,
          data: { label: flow.name },
          parentNode: parent?.id,
          position: { x: 100, y: 100 }
        });

        if (flow.next)
          elements.push({
            data: {
              source: flow.id, target: flow.next.id,
              label: "Followed by"
            }
          });

        // Recurse
        if (flow.children.length)
          addChildrenWithParent(flow, flow, elements);
      }
    };

    const elements: Array<any> = [];
    addChildrenWithParent(root, null, elements);
    return elements;
  }
}
