import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CytoscapeFlowchartGenerator from '../../../model/cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';
import RootStateInterface from '../../../store/model';

// Flowchart view props
type FlowchartProps = {
};

// Flowchart view
const Flowchart: React.FunctionComponent<FlowchartProps> = () => {
  // const [cytoscapeModel, setCytoscapeModel] = useState<Array<any>>([]);
  const [cytoscapeModel, setCytoscapeModel] = useState<any>({});
  const [cytoscapeLayout, setCytoscapeLayout] = useState({ name: 'circle' });

  const openFlowData = useSelector((state: RootStateInterface) => {
    return state.openFlow.data;
  });


  // Cytoscape graph styling
  // @TODO - would like to have  borderWidth: '0' on the outermost container
  const cytoscapeStylesheet = [
    {
      selector: 'node',
      style: {
        width: 20,
        height: 20,
        shape: 'circle',
        backgroundColor: '#ccc',
        label: 'data(label)',
        borderWidth: '0.5'
      }
    },
    {
      selector: ':parent',
      style: {
        'background-color': '#fff'
      }
    },
    {
      selector: 'edge',
      style: {
        width: 2,
        'target-arrow-shape': 'triangle',
        'curve-style': 'bezier'
      }
    }
  ];

  // Fetch data on mount
  useEffect(() => {
    const generateFlow = () => {
      try {
        const generator = new CytoscapeFlowchartGenerator();
        // console.log("openFlowData");
        // console.log(openFlowData);
        const model = generator.generateJSONFromOpenFlow(openFlowData);
        setCytoscapeModel({});
        setCytoscapeLayout({ name: "circle" });
        // // @TODO - this causes a flash, but it works to force update the chart styles
        setTimeout(() => {
          setCytoscapeModel({ ...model, edges: [] });
        }, 100);
        setTimeout(() => {
          setCytoscapeModel(model);
        }, 200);
        setTimeout(() => {
          setCytoscapeLayout({ name: "grid" });
        }, 300);
      }
      catch (e) {
        console.error("Root flow generateFlow failed: " + e);
      }
    };

    generateFlow();
  }, [openFlowData]);

  try {

    return (
      <div className="flowchart-wrapper" style={{ textAlign: 'left' }}>
        <CytoscapeComponent
          elements={CytoscapeComponent.normalizeElements(cytoscapeModel)}
          style={{ width: '100%', height: '100%' }}
          pan={{ x: 300, y: 300 }}
          stylesheet={cytoscapeStylesheet}
          layout={cytoscapeLayout}
        />
      </div>
    );
  } catch (error) {
    return (
      <div>Flowchart Error</div>
    )
  }
};

export default Flowchart;
