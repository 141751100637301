

import React from "react";
import { OrganizationSwitcher } from '@clerk/clerk-react'
import UserBar from '../components/search/UserBar'
import { useNavigate } from "react-router-dom";

function UserSwitcherView() {
  const navigate = useNavigate();

  return (
    <div className="user-wrapper">
      <UserBar />
      <div className="inner-content">
        <p>Please Select your organization< br />(do not select "Personal Workspace")</p>
        <OrganizationSwitcher />
        <p>Then click "Back" in your browser, or click</p>
        <button onClick={() => { navigate("/") }}>Home</button>
      </div>
    </div>
  )
}

export default UserSwitcherView
