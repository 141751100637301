import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NewListColumnItem from './listColumn/NewListColumnItem';
import ListColumnItem from './listColumn/ListColumnItem';
import ListColumnFirstItem from './listColumn/ListColumnFirstItem';
import RootStateInterface from '../../store/model';
import { useNavigate } from 'react-router';

interface Props {
  activeClassName: (input: string) => string,
}

function ListColumn({ activeClassName }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeFlowId = useSelector((state: any) => {
    try {
      return state.activeFlow.data.id;
    } catch (error) {
      console.error(error);
      return null;
    }
  });

  const openFlowData = useSelector((state: RootStateInterface) => {
    return state.openFlow.data;
  });

  function handleNavigate(_newUrl: string) {
    navigate(_newUrl);
  }

  if (openFlowData !== undefined && openFlowData.children !== undefined) {
    return (
      <div className={activeClassName("list-column-wrapper")}>
        <div className="expanded-content">
          <ListColumnFirstItem openFlowData={openFlowData} handleNavigate={handleNavigate} activeFlowId={activeFlowId} />
          <ul className="list">
            {openFlowData.children.map((item: any, index: number) => {
              return <ListColumnItem
                key={index + crypto.randomUUID()}
                item={item}
                index={index}
                dispatch={dispatch}
                openFlowData={openFlowData}
                handleNavigate={handleNavigate}
                activeFlowId={activeFlowId}
              />
            })}
            <NewListColumnItem />
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className={activeClassName("list-column-wrapper")}>
        <p>Waiting for Data : {JSON.stringify(openFlowData)}</p>
      </div>
    );
  }
}

export default ListColumn;
