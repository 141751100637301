import React from 'react';
import SliderBaseComponent from './SliderBaseComponent';
import HeightIcon from '@mui/icons-material/Height';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

class SliderHorizontal extends SliderBaseComponent {
  root: any;
  measurements: any;

  constructor(props: any) {
    super(props);
    this.handleDown = super.handleDown;
    this.root = document.querySelector(':root');
    this.measurements = {};
    this.resizeDimensions();
  };

  resizeDimensions() {
    const coefficient = parseFloat(getComputedStyle(this.root).getPropertyValue('--main-grid-height-row-discuss')) || 0;
    const hundredPercent = window.innerHeight;   
    // @TODO: this 32px is the width of the slider and I think it should not be hard coded
    const discountedHeight = 40;
    const remainingHeight = hundredPercent - discountedHeight;
    this.measurements = {
      coefficient,
      discountedHeight,
      remainingHeight
    }
  }

  handleDrag(event: { pageY: any; }) {
    if (event.pageY > this.measurements.discountedHeight) {
      const correctedPageY = event.pageY - this.measurements.discountedHeight;      
      let fraction = (Math.round((correctedPageY / this.measurements.remainingHeight) * 10)) / 10;
      fraction = parseFloat((1 - fraction).toFixed(1));

      fraction = fraction <= 0 ? 0 : fraction;
      fraction = fraction >= 1 ? 1 : fraction;

      if (fraction !== this.measurements.coefficient) {
        // console.log(`change from ${this.measurements.coefficient} to ${fraction}`);
        this.root.style.setProperty('--main-grid-height-row-discuss', fraction);
        this.measurements.coefficient = fraction;
      }
    }
  }

  expandTo(number: number) {
    this.root.style.setProperty('--main-grid-height-row-discuss', number);
    this.measurements.coefficient = number;
  }

  render() {
    return (
      <div
        className="slider slider-horizontal"
        onMouseDown={(event: any) => this.handleDown(event)}
        onTouchStart={(event: any) => this.handleDown(event)}
        data-grid-label="grid-horizontal"
      >
        <ArrowRightAltIcon onClick={() => {this.expandTo(1)}} style={{ transform: "rotate(270deg)" }} />
        <HeightIcon />
        <ArrowRightAltIcon onClick={() => {this.expandTo(0)}} style={{ transform: "rotate(90deg)" }} />
      </div>
    )
  }
}

export default SliderHorizontal;