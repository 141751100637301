import React from 'react';
import { useDispatch } from 'react-redux';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { createGrandchildForOpenFlow } from '../../../store';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Prompt from '../../shared/Prompt';

interface NestedChildredProps {
  children: any,
  parentId: string,
  parentName: string
}

function NestedChildredIcon({ children, parentId, parentName }: NestedChildredProps) {
  const dispatch = useDispatch();

  function handleNewFlowClick(name: string | null) {
    // console.log("Handle New Flow Click : { name, parentId } = createFlowParams");
    if (name !== "" && name !== null) {
      // @ts-ignore
      const action = createGrandchildForOpenFlow({ name, parentId });
      // @ts-ignore
      dispatch(action);
    }
  }

  if (children.length > 0) {
    return <Link to={`/main/p/${parentId}`}><Tooltip title="See More Detail"><DoubleArrowIcon className="unset-link" /></Tooltip></Link>
  } else {
    return (
      <Prompt message={`Name the first step under "${parentName}"`} defaultValue={''} callback={handleNewFlowClick} >
        <Tooltip title="Add More Detail"><AddCircleOutlineIcon className="cursor-pointer" /></Tooltip>
      </Prompt >
    )
  }
}

export default NestedChildredIcon;