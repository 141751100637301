import React from "react";

function LogoContainer() {
  return (
    <div className="logo-container">
      <img src="./images/floit_logo.png" alt="floit logo" className="logo image-hero"></img>
      <img src="./images/company_logo.png" alt="company-x logo" className="logo image-hero"></img>
    </div>
  );
}

export default LogoContainer;
