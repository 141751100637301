import React from 'react';
import { useDispatch } from 'react-redux';
import Badge from '@mui/material/Badge';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ForumIcon from '@mui/icons-material/Forum';
import { createActiveFlowCommentVote } from '../../../store/index';

import DiscussionBarItems from './DiscussionBarItems';
import NewDiscussion from './NewDiscussion';
import { Card, Tooltip } from '@mui/material';

interface Props {
  item: any
}

function DiscussionBarItem({ item }: Props) {
  const dispatch = useDispatch();

  interface ShowHideDiscussionLayerProps {
    target: any
  }

  function showHideDiscussionLayer({ target }: ShowHideDiscussionLayerProps) {
    function findParentRecursive(el: HTMLElement): HTMLElement {
      // @TODO - too fragile to tie this logic to an element, can we use a data-attribute instead
      if (el.classList.contains('item')) {
        return el;
      } else if (el.parentElement !== null) {
        return findParentRecursive(el.parentElement);
      } else {
        throw new Error('Could not find parent element');
      }
    };
    const parentUl = findParentRecursive(target);
    parentUl.classList.toggle('show-discussion-layer');
  }

  function vote(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, choice: number) {
    let _updateState = false;
    if (item.thisUserHasVoted === 1 && choice === 1) {
      // nothing
    } else if (item.thisUserHasVoted === -1 && choice === -1) {
      // nothing
    } else if (item.thisUserHasVoted === 1 && choice === -1) {
      choice = 0;
      _updateState = true;
    } else if (item.thisUserHasVoted === -1 && choice === 1) {
      choice = 0;
      _updateState = true;
    } else if (item.thisUserHasVoted !== 1 && item.thisUserHasVoted !== -1) {
      _updateState = true;
    } else {
      throw new Error('Uncaught choice');
    }
    if (_updateState === true) {
      let commentId: string = item.id;
      // @ts-ignore
      const action = createActiveFlowCommentVote({ commentId, choice });
      // @ts-ignore
      dispatch(action);
    }
  }

  interface ArrowIconProps {
    vote: number
  }

  function ArrowUpIcon({ vote }: ArrowIconProps) {
    if (vote === 1) {
      return <KeyboardArrowUpIcon color="success" />
    } else if (vote === -1) {
      return <KeyboardArrowUpIcon color="disabled" className="cursor-pointer" />
    } else {
      return <KeyboardArrowUpIcon className="cursor-pointer" />
    }
  }

  function ArrowDownIcon({ vote }: ArrowIconProps) {
    if (vote === -1) {
      return <KeyboardArrowDownIcon color="success" />
    } else if (vote === 1) {
      return <KeyboardArrowDownIcon color="disabled" className="cursor-pointer" />
    } else {
      return <KeyboardArrowDownIcon className="cursor-pointer" />
    }
  }

  try {
    return (
      <li>
        <Card className="item" variant="outlined">
          <div className="right">
            <h3 className="name">{item.userName}</h3>
            <p className="comment">{item.text}</p>
            <div className="votes">
              <Tooltip title="Upvote"><span className="upvotes" onClick={(event) => { vote(event, 1) }}><ArrowUpIcon vote={item.thisUserHasVoted} /></span></Tooltip>
              <span className="netvotes">{item.upvotes - item.downvotes}</span>
              <Tooltip title="Downvote"><span className="downvotes" onClick={(event) => { vote(event, -1) }}><ArrowDownIcon vote={item.thisUserHasVoted} /></span></Tooltip>
              <Badge badgeContent={item.discussion.length} color="secondary">
                <Tooltip title="Comments on this"><ForumIcon onClick={(event) => showHideDiscussionLayer(event)} /></Tooltip>
              </Badge>
            </div>
          </div>
          <ul className="nested-list" data-parent-type={"comment"}>
            <DiscussionBarItems data={item.discussion} />
            <NewDiscussion parentId={item.id} />
          </ul>
        </Card>
      </li>
    )
  } catch (error) {
    return <p>error</p>
  }
}

export default DiscussionBarItem;