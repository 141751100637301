import React from 'react';
import DiscussionBarItem from './DiscussionBarItem';

interface Prop {
  data: any
}

function DiscussionBarItems({ data }: Prop) {
  try {
    if (data !== undefined && data.length > 0) {
      // @ts-ignore @TODO pc
      return data.map((item, index) => {
        return (
          <DiscussionBarItem key={index} item={item} />
        )
      });
    } else {
      return <div></div>;
    }
  } catch (error) {
    console.log(error);
  }
}

export default DiscussionBarItems;