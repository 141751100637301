import React from "react";

interface Props {
  index: number
  text: string
  tagName: string
  smallActiveView: any
  setSmallActiveView: any
}

function Listitem({ index, text, tagName, smallActiveView, setSmallActiveView }: Props) {

  const _className = smallActiveView === tagName ? 'selector active' : 'selector';
  
  return (
    <div
      key={index}
      className={_className}
      data-tag={tagName}
      onClick={(event: any) => { setSmallActiveView(event.target.dataset.tag) }}
    >{text}</div>
  )

}

export default Listitem;
