import React from "react";
import Listitem from './smallStateManager/Listitem';

interface Props {
  smallActiveView: string;
  setSmallActiveView: any;
}

function SmallStateManager({ smallActiveView, setSmallActiveView }: Props) {
  interface Item {
    tagName: string;
    text: string;
  }

  const data = [{
    tagName: 'list-column-wrapper',
    text: 'ListColumn'
  },
  {
    tagName: 'richtext-column-wrapper',
    text: 'RichTextColumn'
  },
  {
    tagName: 'flowchart-column-wrapper',
    text: 'FlowChartColumn'
  },
  {
    tagName: 'discussion-bar-wrapper',
    text: 'DiscussionBar'
  }];


  return (
    <div className="small-state-manager">
      {
        data.map((item: Item, index: number) => {
          return <Listitem
            key={index}
            index={index}
            text={item.text}
            tagName={item.tagName}
            smallActiveView={smallActiveView}
            setSmallActiveView={setSmallActiveView} />
        })
      }
    </div>
  )
}

export default SmallStateManager;
